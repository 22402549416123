import React from "react";
import Navbar from "../navbar/Navbar";
import Background from '../../assets/img/background/Terms-Conditons.png';
import FooterMain from "../FooterMainSection/FooterMain";
import Footer from "../footer-section/Footer";

const TermsCondition = () => {
    return (
        <>
            <div>
                <Navbar/>
                <div className="page-area" style={{backgroundImage: `url(${Background})`}}>
                    <div className="breadcumb-overlay"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb" style={{display: "block"}}>
                                    <div className="section-headline">
                                        <h2>Terms &amp; Conditions</h2>
                                    </div>
                                    <ul>
                                        <li className="home-bread">
                                            <a href="/" className="nuxt-link-active">
                                                Home
                                            </a>
                                        </li>
                                        <li>Terms &amp; Conditions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms-area bg-color-2 area-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h3>Terms &amp; Conditions</h3>{" "}
                                    <p>
                                        Welcome to www.indiasfutureinvestors.com. This Website and App are owned and
                                        operated by Invest19 Technologies ("Invest19," "we," "our"). By accessing or
                                        using the Website and App, you agree to be bound by these Terms & Conditions
                                        ("Terms"). If you do not agree with these Terms, please refrain from using the
                                        Website or App.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="company-terms">
                                    <div className="single-terms">
                                        <h4><span className="number">1.</span>
                                            <span className="terms-text">
                                                   Eligibility to Participate
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <h5>1.1 Age Requirement :</h5>
                                            <ul className="ifi-list">
                                                <li>The IFI TV Show is open to individuals aged 18-60 years.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>1.2 Residency & Nationality :</h5>
                                            <ul className="ifi-list">
                                                <li>The IFI TV Show is open to both Indian residents and foreign
                                                    nationals.
                                                </li>
                                                <li>Foreign nationals must hold a valid passport and comply with visa
                                                    requirements to participate in the show.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>1.3 Identification Proof :</h5>
                                            <ul className="ifi-list">
                                                <li>All participants must provide valid identification proof.
                                                    <ul>
                                                        <li>Indian participants: Aadhaar Card, PAN Card, or Passport.
                                                        </li>
                                                        <li>Foreign participants: Passport and valid visa for India.
                                                        </li>
                                                    </ul>
                                                </li>

                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>1.4 Merchandise Requirement :</h5>
                                            <ul className="ifi-list">
                                                <li>Purchase of official IFI merchandise (e.g., T-shirts, hoodies) is
                                                    mandatory for participation in auditions and events.
                                                </li>
                                                <li>This merchandise purchase is a required step for participation in
                                                    the show and is intended to serve as both a supporting contribution
                                                    to the event and a part of the participant experience.
                                                </li>
                                                <li>Merchandise is non-refundable, except in cases of manufacturing
                                                    defects, damage during delivery, or incorrect items received.
                                                </li>

                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">2.</span>
                                            <span className="terms-text">
                                                   Ranking and Winner Selection
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>The ranking and selection of winners in the India's Future Investors
                                                    (IFI) show will be based on both judges' scores and viewer votes.
                                                </li>
                                                <li>Invest19 Technologies, its partners, employees, sponsors, and
                                                    affiliates are not responsible for the outcome of the selection
                                                    process. The final decisions are determined by the judges and viewer
                                                    votes, and all participants agree to abide by these decisions.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">3.</span>
                                            <span className="terms-text">
                                                   Indemnification
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>By participating in the IFI TV Show, the Website, or the App, you
                                                    agree to indemnify, defend, and hold harmless Invest19 Technologies,
                                                    its partners, employees, sponsors, affiliates, and any third-party
                                                    providers from any and all claims, losses, damages, liabilities, and
                                                    expenses (including legal fees) arising out of your participation in
                                                    the Show, use of the Website or App, or any violation of these
                                                    Terms.
                                                </li>
                                                <li>Invest19 Technologies is not responsible for any loss of any kind,
                                                    whether direct, indirect, or consequential, arising from the use of
                                                    the Website, App, or participation in the IFI Show.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">4.</span>
                                            <span className="terms-text">
                                                   Purpose of the Show
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>The India's Future Investors TV Show is intended solely for
                                                    educational purposes. It aims to provide financial literacy and
                                                    empower individuals with knowledge about investing.
                                                </li>
                                                <li> Invest19 Technologies, its promoters, employees, partners, and
                                                    sponsors will not be responsible for any profit or loss incurred,
                                                    directly or indirectly, by any individual as a result of their
                                                    participation in the Show or based on the educational content
                                                    provided through the Website, App, or Show.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">5.</span>
                                            <span className="terms-text">
                                                  Referral Policy
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <h5>5.1 Eligibility to Refer :</h5>
                                            <ul className="ifi-list">
                                                <li>Any registered user of the IFI Website can participate in the
                                                    referral program.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>5.2 Referral Process :</h5>
                                            <ul className="ifi-list">
                                                <li>Each participant will be provided with a unique referral code/link
                                                    to share with others.
                                                </li>
                                                <li>When a referred participant registers using the referral code/link
                                                    and successfully
                                                </li>
                                                <li>completes the mandatory steps (e.g., purchasing merchandise), the
                                                    referral is counted as valid.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>5.3 Referral Rewards :</h5>
                                            <ul className="ifi-list">
                                                <li>Referrers may receive wallet credits or cash rewards as specified at
                                                    the time of the referral campaign.
                                                </li>
                                                <li>If cash rewards are provided, TDS (Tax Deducted at Source) or any
                                                    other applicable tax as per Indian laws will be deducted.
                                                </li>
                                                <li>Cash rewards will be transferred to the referrer's bank account,
                                                    subject to valid details being provided.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>5.4 Refer and Win INR 1,00,00,000 :</h5>
                                            <ul className="ifi-list">
                                                <li>If the participant you refer wins the IFI Season 1 Title, you, as
                                                    the referrer, will be eligible to win INR 1,00,00,000.
                                                </li>
                                                <li>This prize will be paid out as a cash reward to the referrer,
                                                    subject to applicable taxes being deducted at source (TDS).
                                                </li>
                                                <li>The reward will be transferred to the referrer's bank account once
                                                    the referred participant is declared the winner of the season.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>5.5 Referral Limits :</h5>
                                            <ul className="ifi-list">
                                                <li>IFI reserves the right to limit the number of valid referrals per
                                                    user or modify referral benefits at its discretion.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>5.6 Fraudulent Activity :</h5>
                                            <ul className="ifi-list">
                                                <li>Any fraudulent or abusive use of the referral program (e.g.,
                                                    creating fake accounts, self-referrals) will result in
                                                    disqualification from the program and forfeiture of all referral
                                                    rewards.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>5.7 Program Changes :</h5>
                                            <ul className="ifi-list">
                                                <li>IFI reserves the right to modify, suspend, or terminate the referral
                                                    program without prior notice.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">6.</span>
                                            <span className="terms-text">
                                                  Merchandise Policy
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <h5>6.1 Purchase of Merchandise :</h5>
                                            <ul className="ifi-list">
                                                <li>Purchase of official IFI merchandise (e.g., T-shirts, hoodies) is
                                                    mandatory for participation in the show.
                                                </li>
                                                <li>Merchandise is a required part of entry into the IFI TV Show, and
                                                    this purchase helps support the production and hosting of the show.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>6.2 Return Policy :</h5>
                                            <ul className="ifi-list">
                                                <li>Merchandise can only be returned or exchanged in case of
                                                    <ul>
                                                        <li>Manufacturing defects</li>
                                                        <li>Damage during delivery</li>
                                                        <li>Incorrect items received</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>6.3 Return Process :</h5>
                                            <ul className="ifi-list">
                                                <li>Customers must contact our support team within 7 days of receiving
                                                    the product to report an issue
                                                </li>
                                                <li>To initiate a return, customers must provide
                                                    <ul>
                                                        <li>Order details</li>
                                                        <li>Proof of purchase</li>
                                                        <li>Photographic evidence of the defect or damage</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>6.4 Replacement or Refund :</h5>
                                            <ul className="ifi-list">
                                                <li>Verified issues will be addressed with a replacement, subject to
                                                    stock availability.
                                                </li>
                                                <li>If a replacement is not possible, a refund will be issued.</li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>6.5 Refund Method :</h5>
                                            <ul className="ifi-list">
                                                <li>Refunds will be credited to the customer’s wallet on the Website.
                                                </li>
                                                <li>No refunds will be processed directly to bank accounts or other
                                                    payment methods.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">7.</span>
                                            <span className="terms-text">Wallet Policy</span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <h5>7.1 Usage of Wallet :</h5>
                                            <ul className="ifi-list">
                                                <li>Funds credited to the wallet can only be used for purchases on the
                                                    Website.
                                                </li>
                                                <li>Wallet balances are non-transferable and cannot be converted into
                                                    cash.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>7.2 Validity of Wallet Balance :</h5>
                                            <ul className="ifi-list">
                                                <li>Wallet balances must be used within the specified validity period,
                                                    if applicable.
                                                </li>
                                                <li>Unused wallet funds after expiry will not be refunded or extended.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>

                                    <div className="single-terms">
                                        <h4>
                                            <span className="number">8.</span>
                                            <span className="terms-text">Participation Process</span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <h5>8.1 Registration :</h5>
                                            <ul className="ifi-list">
                                                <li>Complete the registration form with your details and age proof to
                                                    access the IFI Dashboard.
                                                </li>

                                            </ul>

                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>8.2 Open Audition :</h5>
                                            <ul className="ifi-list">
                                                <li>Submit a video about your investment journey, challenges, and vision
                                                    for wealth creation. Wear official IFI merchandise, share the video
                                                    on social media, and submit the post URL to the IFI Dashboard.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>8.3 Virtual Audition :</h5>
                                            <ul className="ifi-list">
                                                <li>Shortlisted participants are invited to take an online quiz.
                                                    Reapplication fees apply for multiple quiz attempts.
                                                </li>
                                            </ul>

                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>8.4 Live Auditions :</h5>
                                            <ul className="ifi-list">
                                                <li>Select your audition city from available options on the website and
                                                    attend the live auditions.
                                                </li>

                                            </ul>

                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>8.5 Main Show :</h5>
                                            <ul className="ifi-list">
                                                <li>Contestants who pass auditions enter the Main Show, facing
                                                    challenges like portfolio building and market analysis.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>8.6 Final Round :</h5>
                                            <ul className="ifi-list">
                                                <li>Top contestants from the Main Show compete in advanced tasks to be
                                                    named one of India’s Future Investors.
                                                </li>
                                            </ul>

                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4>
                <span className="number">
                  9.
                </span>
                                            <span className="terms-text">
                  Privacy
                </span>
                                        </h4>
                                        <p>
                                            Refer to our Privacy Policy for information on how your personal data is
                                            collected, used, and protected.
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4>
                <span className="number">
                  10.
                </span>
                                            <span className="terms-text">
                  Cookie Policy
                </span>
                                        </h4>
                                        <p>
                                            Refer to our Cookie Policy for details on how cookies are used to enhance
                                            your browsing experience, improve functionality, and analyse site traffic.
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4>
                                            <span className="number">11.</span>
                                            <span className="terms-text">
                                               Contact Us
                                             </span>
                                        </h4>
                                        <p>
                                            Email: <a
                                            href="mailto:support@indiasfutureinvestors.com" target="_blank"> support@indiasfutureinvestors.com</a>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4>
                                            <span className="number">12.</span>
                                            <span className="terms-text">
                                               Modification of Show Format or Rules
                                             </span>
                                        </h4>
                                        <p>
                                           IFI reserves the right to change the show format, rules, or Terms & Conditions at any time without prior notice.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain/>
                <Footer/>

            </div>
        </>
    )
}
export default TermsCondition;