import React from 'react'
import Navbar from "../navbar/Navbar";
import Background from "../../assets/img/background/Privacy-policy.png";
import FooterMain from "../FooterMainSection/FooterMain";
import Footer from "../footer-section/Footer";

const TeamCaption = () => {
    return (
        <>
            <div>
                <Navbar/>
                <div className="page-area" style={{backgroundImage: `url(${Background})`}}>
                    <div className="breadcumb-overlay"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb" style={{display: "block"}}>
                                    <div className="section-headline">
                                        <h2>Team Captain </h2>
                                    </div>
                                    <ul>
                                        <li className="home-bread">
                                            <a href="/" className="nuxt-link-active">
                                                Home
                                            </a>
                                        </li>
                                        <li>Team Captain </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms-area bg-color-2 area-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h3>Become a Team Captain on India’s Future Investors</h3>
                                    <p>
                                        Are you ready to inspire and lead India’s brightest investment enthusiasts? As a Team Captain on India’s Future Investors, you’ll mentor participants, strategize for challenges, and play a crucial role in shaping their journey toward becoming future financial leaders.

                                    </p>
                                    <p>Whether you're an educator, influencer, or investment professional, this is your opportunity to make an impact!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="company-terms">
                                    <div className="single-terms">
                                        <h4><span className="number">1.</span>
                                            <span className="terms-text">
                                                   Why Join as a Team Captain?
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li><b>Lead with Purpose:</b> Guide participants through exciting challenges and share your expertise.

                                                </li>
                                                <li><b>Build Your Brand:</b> Gain visibility as a dynamic financial educator or influencer.
                                                </li>
                                                <li><b>Collaborate with Experts:</b> Work alongside judges and industry leaders in an iconic show.
                                                </li>
                                                <li><b>Expand Your Reach:</b> Increase your influence by connecting with a national audience.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">2.</span>
                                            <span className="terms-text">
                                                   Eligibility Criteria
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Open to financial educators, investment professionals, and influencers with a passion for financial literacy.
                                                </li>
                                                <li>Special preference for stock market influencers with a significant online following and proven expertise in financial or investment education.
                                                </li>
                                                <li>
                                                    Aged 25–40 years, with experience in mentoring, coaching, or team leadership.
                                                </li>
                                                <li>Strong communication and problem-solving skills.</li>
                                                <li>A commitment to promoting financial education and youth development.</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">3.</span>
                                            <span className="terms-text">
                                                   Application Process
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li><b>Step 1:</b> Complete the application form below.</li>
                                                <li><b>Step 2:</b> Share your professional profile and a video introducing yourself and your vision for financial education.</li>
                                                <li><b>Step 3:</b> Shortlisted applicants will be interviewed by the show’s producers.</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain/>
                <Footer/>

            </div>
        </>
    )
}
export default TeamCaption