// import React from 'react'
// import Logo from "../../assets/img/logo/logom2.gif";
//
// const Navbar = () =>{
//     return(
//         <>
//             <header className="header-one">
//                 <div id="sticker" className="header-area hidden-xs">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-3 col-sm-3">
//                                 <div className="logo">
//                                     <a href="/" className="navbar-brand">
//                                         <img src={Logo} alt='Logo' style={{paddingTop: '23px'}}/>
//                                     </a>
//                                 </div>
//                             </div>
//                             <div className="col-md-9 col-sm-9">
//                                 <div className="header-right-link">
//                                     <a className="s-menu" href='/sign-up'>
//                                         Sign Up
//                                     </a>
//                                 </div>
//                                 <nav className="navbar navbar-default">
//                                     <div
//                                         id="navbar-example"
//                                         className="collapse navbar-collapse"
//                                     >
//                                         <div className="main-menu">
//                                             <ul
//                                                 className="nav navbar-nav navbar-right"
//                                                 style={{display: 'block'}}
//                                             >
//                                                 <li data-v-45fa8f74="">
//                                                     <a href="/partnership" className="pagess">
//                                                         Partnership
//                                                     </a>
//                                                 </li>
//                                                 <li data-v-45fa8f74="">
//                                                     <a
//                                                         href="/sponsorship"
//                                                         className="pagess"
//
//                                                     >
//                                                         Sponsorship
//                                                     </a>
//                                                 </li>
//
//
//                                                 {/*<li>*/}
//                                                 {/*    <a*/}
//                                                 {/*        href="/terms-condition"*/}
//                                                 {/*        className="pagess"*/}
//
//                                                 {/*    >*/}
//                                                 {/*        Terms and Conditions*/}
//                                                 {/*    </a>*/}
//                                                 {/*</li>*/}
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div
//                     className="mobile-menu-area hidden-lg hidden-md hidden-sm"
//                 >
//                     <div className="container">
//                     <div className="row">
//                         <div className="col-md-12">
//                                 <div className="mobile-menu">
//                                     <div className="logo">
//                                         <a href="/index">
//                                             <img src={Logo} alt="Logo"/>
//                                         </a>
//                                     </div>
//                                     <div
//                                         id="menu-button"
//                                         className="menu-button"
//                                     >
//                                         <i className="fa fa-bars"/>
//                                     </div>
//                                     <nav id="dropdown">
//                                         <ul style={{display: "none"}}>
//                                             <li>
//                                                 <a href="index" className="pagess">
//                                                     Home
//                                                 </a>
//                                                 <ul className="sub-menu">
//                                                     <li>
//                                                         <a href="/index">
//                                                             Home 01
//                                                         </a>
//                                                     </li>
//                                                     <li data-v-45fa8f74="">
//                                                         <a href="/index-2">
//                                                             Home 02
//                                                         </a>
//                                                     </li>
//                                                     <li data-v-45fa8f74="">
//                                                         <a href="/index-3">
//                                                             Home 03
//                                                         </a>
//                                                     </li>
//                                                 </ul>
//                                             </li>
//                                             <li data-v-45fa8f74="">
//                                                 <a href="play" className="pagess">
//                                                     Play
//                                                 </a>
//                                             </li>
//                                             <li data-v-45fa8f74="">
//                                                 <a href="#" className="pagess">
//                                                     Games
//                                                 </a>
//                                                 <ul className="sub-menu">
//                                                     <li>
//                                                         <a href="/games">
//                                                             All Games
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/games-details">
//                                                             Games Details
//                                                         </a>
//                                                     </li>
//                                                 </ul>
//                                             </li>
//                                             <li>
//                                                 <a href="lottery" className="pagess">
//                                                     Lottery
//                                                 </a>
//                                             </li>
//                                             <li>
//                                                 <a href="#" className="pagess">
//                                                     Pages
//                                                 </a>
//                                                 <ul className="sub-menu">
//                                                     <li>
//                                                         <a href="/about">
//                                                             About
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/team">
//                                                             team
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/faq">
//                                                             FAQ
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/pricing">
//                                                             Pricing
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/login" data-v-45fa8f74="">
//                                                             Login
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/signup" data-v-45fa8f74="">
//                                                             Signup
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/error" data-v-45fa8f74="">
//                                                             Error
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/terms" data-v-45fa8f74="">
//                                                             terms &amp; Conditions
//                                                         </a>
//                                                     </li>
//                                                 </ul>
//                                             </li>
//                                             <li>
//                                                 <a href="#" className="pagess">
//                                                     Blog
//                                                 </a>
//                                                 <ul className="sub-menu">
//                                                     <li>
//                                                         <a href="/blog">
//                                                             Blog grid
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/blog-sidebar">
//                                                             Blog Sidebar
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/blog-details">
//                                                             Blog Details
//                                                         </a>
//                                                     </li>
//                                                     <li>
//                                                         <a href="/faq">
//                                                            FAQ
//                                                         </a>
//                                                     </li>
//                                                 </ul>
//                                             </li>
//                                             <li data-v-45fa8f74="">
//                                                 <a href="/contact" data-v-45fa8f74="">
//                                                     contacts
//                                                 </a>
//                                             </li>
//                                         </ul>
//                                     </nav>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </header>
//         </>
//     )
// }
// export default Navbar

import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo/logom2.gif";

const Navbar = () => {
    return (
        <>
            <header className="header-one">
                <div id="sticker" className="header-area hidden-xs">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-3">
                                <div className="logo">
                                    <Link to="/" className="navbar-brand">
                                        <img src={Logo} alt='Logo' style={{ paddingTop: '23px' }} />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-9">
                                <div className="header-right-link">
                                    <Link className="s-menu" to='/login'>
                                        Login
                                    </Link>
                                </div>
                                <nav className="navbar navbar-default">
                                    <div
                                        id="navbar-example"
                                        className="collapse navbar-collapse"
                                    >
                                        <div className="main-menu">
                                            <ul
                                                className="nav navbar-nav navbar-right"
                                                style={{ display: 'block' }}
                                            >
                                                <li data-v-45fa8f74="">
                                                    <Link to="/partnership" className="pagess">
                                                        Partnership
                                                    </Link>
                                                </li>
                                                <li data-v-45fa8f74="">
                                                    <Link
                                                        to="/sponsorship"
                                                        className="pagess"
                                                    >
                                                        Sponsorship
                                                    </Link>
                                                </li>

                                                {/* Uncomment if needed */}
                                                {/* <li>
                                                    <Link
                                                        to="/terms-condition"
                                                        className="pagess"
                                                    >
                                                        Terms and Conditions
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="mobile-menu-area hidden-lg hidden-md hidden-sm"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mobile-menu">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={Logo} alt="Logo" />
                                        </Link>
                                    </div>
                                    <div
                                        id="menu-button"
                                        className="menu-button"
                                    >
                                        <i className="fa fa-bars" />
                                    </div>
                                    <nav id="dropdown">
                                        <ul style={{ display: "none" }}>
                                            <li>
                                                <Link to="/" className="pagess">
                                                    Home
                                                </Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <Link to="/">
                                                            Home 01
                                                        </Link>
                                                    </li>
                                                    <li data-v-45fa8f74="">
                                                        <Link to="/index-2">
                                                            Home 02
                                                        </Link>
                                                    </li>
                                                    <li data-v-45fa8f74="">
                                                        <Link to="/index-3">
                                                            Home 03
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li data-v-45fa8f74="">
                                                <Link to="/play" className="pagess">
                                                    Play
                                                </Link>
                                            </li>
                                            <li data-v-45fa8f74="">
                                                <Link to="#" className="pagess">
                                                    Games
                                                </Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <Link to="/games">
                                                            All Games
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/games-details">
                                                            Games Details
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/lottery" className="pagess">
                                                    Lottery
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" className="pagess">
                                                    Pages
                                                </Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <Link to="/about">
                                                            About
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/team">
                                                            Team
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/faq">
                                                            FAQ
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/pricing">
                                                            Pricing
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/login" data-v-45fa8f74="">
                                                            Login
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/signup" data-v-45fa8f74="">
                                                            Signup
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/error" data-v-45fa8f74="">
                                                            Error
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/terms" data-v-45fa8f74="">
                                                            Terms &amp; Conditions
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="#" className="pagess">
                                                    Blog
                                                </Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <Link to="/blog">
                                                            Blog Grid
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/blog-sidebar">
                                                            Blog Sidebar
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/blog-details">
                                                            Blog Details
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/faq">
                                                            FAQ
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li data-v-45fa8f74="">
                                                <Link to="/contact" data-v-45fa8f74="">
                                                    Contacts
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Navbar;
