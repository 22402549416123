// import React from 'react'
// import Img1 from '../../assets/img/about/Open.png'
// import Img2 from '../../assets/img/about/Quiz.png'
// import Img3 from '../../assets/img/about/Live.png'
//
// const OpeningSoon = () => {
//     return (
//         <>
//             <div className="achivement-area bg-color area-padding-2">
//                 <div className="container main-section">
//                     <div className="row" style={{display:'block'}}>
//                         <div className="achivement-content">
//                             <h1>Auditions Opening Soon</h1>
//                             <div className="col-md-4 col-sm-4 col-xs-12">
//                                 <div className="single-achive second-achive">
//                                     <div className="achive-image">
//                                         <img src={Img1} alt="" style={{width:'100px'}}
//                                         />
//                                     </div>
//                                     <div className="achivement-text">
//                                        <span className="achive-number">
//                                           Open Audition
//                                        </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-4 col-sm-4 col-xs-12">
//                                 <div className="single-achive first-achive">
//                                     <div className="achive-image">
//                                         <img src={Img2} alt="" style={{width:'100px'}}
//                                         />
//                                     </div>
//                                     <div className="achivement-text">
//                                         <span className="achive-number">
//                                             Virtual Audition
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-4 col-sm-4 col-xs-12">
//                                 <div className="single-achive second-achive">
//                                     <div className="achive-image">
//                                         <img src={Img3} alt="" style={{width:'100px'}}
//                                         />
//                                     </div>
//                                     <div className="achivement-text">
//                                        <span className="achive-number">
//                                           Live Audition
//                                        </span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }
// export default OpeningSoon

import React from 'react';
import Img1 from '../../assets/img/about/Open.png';
import Img2 from '../../assets/img/about/Quiz.png';
import Img3 from '../../assets/img/about/Live.png';
// import './OpeningSoon.css'; // Add a CSS file for better organization

const OpeningSoon = () => {
    return (
        <>
            <div className="achivement-area bg-color area-padding-2">
                <div className="container main-section">
                    <div className="row" style={{ display: 'block' }}>
                        <div className="achivement-content">
                            <h1>Auditions Opening Soon</h1>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-achive second-achive hover-effect">
                                    <div className="achive-image">
                                        <img src={Img1} alt="" />
                                    </div>
                                    <div className="achivement-text">
                                        <span className="achive-number">
                                            Open Audition
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-achive first-achive hover-effect">
                                    <div className="achive-image">
                                        <img src={Img2} alt="" />
                                    </div>
                                    <div className="achivement-text">
                                        <span className="achive-number">
                                            Virtual Audition
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-achive second-achive hover-effect">
                                    <div className="achive-image">
                                        <img src={Img3} alt="" />
                                    </div>
                                    <div className="achivement-text">
                                        <span className="achive-number">
                                            Live Audition
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default OpeningSoon;
