// import React from 'react'
// import Logo from '../../assets/img/logo/footer-logo.png'
//
//
// const FooterMain = () =>{
//     return(
//         <>
//             <div>
//                 <footer className="footer-1">
//                     <div className="footer-area">
//                         <div className="container">
//                             <div className="row">
//                                 <div className="col-md-4 col-sm-6 col-xs-12">
//                                     <div className="footer-content logo-footer">
//                                         <div className="footer-head">
//                                             <div className="footer-logo">
//                                                 <a href="#" className="footer-black-logo">
//                                                     <img src={Logo} alt="" width='240'/>
//                                                 </a>
//                                             </div>
//                                             <p>
//                                                 Empowering a financially savvy generation by blending education, entertainment, and technology. Join us in shaping India's future investors.
//                                             </p>
//                                             <div className="subs-feilds">
//                                                 <div className="suscribe-input">
//                                                     <input
//                                                         type="email"
//                                                         id="sus_email"
//                                                         placeholder="Type Email"
//                                                         className="email form-control width-80"
//                                                     />
//                                                     <button
//                                                         type="submit"
//                                                         id="sus_submit"
//                                                         className="add-btn"
//                                                     >
//                                                         Subscribe
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-2 col-sm-3 col-xs-12">
//                                     <div className="footer-content">
//                                         <div className="footer-head">
//                                             <h4>SHOW DETAILS</h4>
//                                             <ul className="footer-list">
//                                                 <li style={{cursor:"none"}}>
//                                                     <a href="/" style={{cursor:"none"}}>
//                                                         About the Show
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/">
//                                                         Stages & Format
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/apply-judges">
//                                                         Apply for Judges
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/team-captions">
//                                                         Apply for T. Captains
//                                                     </a>
//                                                 </li>
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-2 col-sm-3 col-xs-12">
//                                     <div className="footer-content">
//                                         <div className="footer-head">
//                                             <h4>Collaborations</h4>{" "}
//                                             <ul className="footer-list">
//                                                 <li>
//                                                     <a href="/">
//                                                         Partners Benefits
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/">
//                                                         Sponsor Benefits
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/">
//                                                         Influencers Benefits
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/csr-impact">
//                                                         CSR & Impact
//                                                     </a>
//                                                 </li>
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-2 hidden-sm col-xs-12">
//                                     <div className="footer-content">
//                                         <div className="footer-head">
//                                             <h4>RESOURCES</h4>
//                                             <ul className="footer-list">
//                                                 <li>
//                                                     <a href="/">
//                                                         Merchandise Store
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/">
//                                                         Quiz Platform
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/">
//                                                         Financial Literacy
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/">
//                                                         Investor Workshops
//                                                     </a>
//                                                 </li>
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-2 hidden-sm col-xs-12">
//                                     <div className="footer-content last-content">
//                                         <div className="footer-head">
//                                             <h4>Support</h4>{" "}
//                                             <ul className="footer-list">
//                                                 <li>
//                                                     <a href="/">
//                                                         Contact Us
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/faq">
//                                                         FAQs
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/terms-conditions">
//                                                         Terms & Conditions
//                                                     </a>
//                                                 </li>
//                                                 <li>
//                                                     <a href="/privacy-policy">
//                                                         Privacy Policy
//                                                     </a>
//                                                 </li>
//
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </footer>
//                 {/*<a*/}
//                 {/*    id="scrollUp"*/}
//                 {/*    href="#"*/}
//                 {/*    style={{position: "fixed", zIndex: 2147483647, display: "block"}}*/}
//                 {/*>*/}
//                 {/*    <i className="ti-arrow-up"/>*/}
//                 {/*</a>*/}
//             </div>
//         </>
//     )
// }
// export default FooterMain
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo/footer-logo.png';

const FooterMain = () => {
    return (
        <>
            <div>
                <footer className="footer-1">
                    <div className="footer-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="footer-content logo-footer">
                                        <div className="footer-head">
                                            <div className="footer-logo">
                                                <Link to="/" className="footer-black-logo">
                                                    <img src={Logo} alt="" width='240'/>
                                                </Link>
                                            </div>
                                            <p>
                                                Empowering a financially savvy generation by blending education, entertainment, and technology. Join us in shaping India's future investors.
                                            </p>
                                            <div className="subs-feilds">
                                                <div className="suscribe-input">
                                                    <input
                                                        type="email"
                                                        id="sus_email"
                                                        placeholder="Type Email"
                                                        className="email form-control width-80"
                                                    />
                                                    <button
                                                        type="submit"
                                                        id="sus_submit"
                                                        className="add-btn"
                                                    >
                                                        Subscribe
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-3 col-xs-12">
                                    <div className="footer-content">
                                        <div className="footer-head">
                                            <h4>SHOW DETAILS</h4>
                                            <ul className="footer-list">
                                                <li>
                                                    <Link to="/">About the Show</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Stages & Format</Link>
                                                </li>
                                                <li>
                                                    <Link to="/apply-judges">Apply for Judges</Link>
                                                </li>
                                                <li>
                                                    <Link to="/team-captions">Apply for T. Captains</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-3 col-xs-12">
                                    <div className="footer-content">
                                        <div className="footer-head">
                                            <h4>Collaborations</h4>{" "}
                                            <ul className="footer-list">
                                                <li>
                                                    <Link to="/">Partners Benefits</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Sponsor Benefits</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Influencers Benefits</Link>
                                                </li>
                                                <li>
                                                    <Link to="/csr-impact">CSR & Impact</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 hidden-sm col-xs-12">
                                    <div className="footer-content">
                                        <div className="footer-head">
                                            <h4>RESOURCES</h4>
                                            <ul className="footer-list">
                                                <li>
                                                    <Link to="/">Merchandise Store</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Quiz Platform</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Financial Literacy</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Investor Workshops</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 hidden-sm col-xs-12">
                                    <div className="footer-content last-content">
                                        <div className="footer-head">
                                            <h4>Support</h4>{" "}
                                            <ul className="footer-list">
                                                <li>
                                                    <Link to="/">Contact Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/faq">FAQs</Link>
                                                </li>
                                                <li>
                                                    <Link to="/terms-conditions">Terms & Conditions</Link>
                                                </li>
                                                <li>
                                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default FooterMain;
