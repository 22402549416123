import React from 'react'
import Bg from "../assets/img/background/Partnership.png";
import Navbar from "./navbar/Navbar";
import FooterMain from "./FooterMainSection/FooterMain";
import Footer from "./footer-section/Footer";
import Background from "../assets/img/background/1.png";

const Partnership = () => {
    const industyType = [
        {
            id:1,
            name:'Technology'
        },
        {
            id:2,
            name:'Media & Entertainment'
        },
        {
            id:3,
            name:'Financial Platforms'
        },
        {
            id:4,
            name:'Outdoor Advertising'
        },
        {
            id:5,
            name:'Airlines'
        },
        {
            id:7,
            name:'Hospitality'
        },
        {
            id:8,
            name:'Education'
        },
        {
            id:9,
            name:'Other'
        }
    ]


    const partnershipType = [
        {
            id:1,
            name:'Investment Platform Partner'
        },
        {
            id:2,
            name:'Technology Partner'
        },
        {
            id:3,
            name:'Media/OTT Partner'
        },
        {
            id:4,
            name:'Radio Partner'
        },
        {
            id:5,
            name:'Multiplex Partner'
        },
        {
            id:6,
            name:'Airline Partner'
        },
        {
            id:7,
            name:'Hotel Partner'
        },
        {
            id:8,
            name:'Growth and Engagement Partner'
        },
        {
            id:9,
            name:'Financial News Platform Partner'
        },
        {
            id:10,
            name:'Other'
        }
    ]
    const mode = [
        {
            id:1,
            name:'Email'
        },
        {
            id:2,
            name:'Phone'
        }
    ]

    return (
        <>
            <div>
                <Navbar/>
                <div className="page-area" style={{backgroundImage: `url(${Bg})`}}>
                    <div className="breadcumb-overlay"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb" style={{display: "block"}}>
                                    <div className="section-headline">
                                        <h2>Partnership</h2>
                                    </div>
                                    <ul>
                                        <li className="home-bread">
                                            <a href="/" className="nuxt-link-active">
                                                Home
                                            </a>
                                        </li>
                                        <li>Partnership</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms-area bg-color-2 area-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h3>Empower. Inspire. Transform the Future of Investing in India.</h3>
                                    <p>
                                        India’s Future Investors (IFI) invites industry leaders, innovators, and
                                        visionaries to join us in reshaping the financial literacy and investment
                                        landscape of India. By partnering with IFI, you not only contribute to creating
                                        a new generation of informed investors but also elevate your brand's presence
                                        across diverse platforms and audiences.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="company-terms">
                                    <div className="single-terms">
                                        <h4 style={{
                                            paddingTop: '8px',
                                            paddingBottom: '12px',
                                            fontSize: '26px',
                                            color: 'white',
                                            fontWeight: '600'
                                        }}>Why Partner with IFI?</h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li><b>Unparalleled Reach :</b> Connect with millions of viewers across
                                                    television, OTT platforms, digital channels, and more.
                                                </li>
                                                <li><b>Exclusive Visibility :</b> Position your brand alongside a
                                                    transformative initiative that resonates with aspiring investors and
                                                    financial enthusiasts.
                                                </li>
                                                <li><b>Impactful Collaboration :</b> Be a key contributor to India's
                                                    financial education movement, helping drive growth and empowerment.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4 style={{
                                            paddingTop: '8px',
                                            paddingBottom: '12px',
                                            fontSize: '26px',
                                            color: 'white',
                                            fontWeight: '600'
                                        }}>Our Partnership Categories</h4>
                                        <h4><span className="number">1.</span>
                                            <span className="terms-text">
                                                  Investment Platform Partner
                                            </span>
                                        </h4>
                                        <p>Collaborate with us to provide aspiring investors with tools and resources
                                            that simplify trading and investing. Together, we’ll create smarter
                                            financial strategies for the future.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">2.</span>
                                            <span className="terms-text">
                                                  Technology Partner
                                            </span>
                                        </h4>
                                        <p>Become the enabler of innovation by powering IFI with cutting-edge financial
                                            technology and tools. Showcase your expertise as a key driver of digital
                                            transformation in the investment space.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">3.</span>
                                            <span className="terms-text">
                                                  Outdoor Advertising Partner
                                            </span>
                                        </h4>
                                        <p>Utilize premium outdoor spaces to maximize visibility and create widespread
                                            awareness about IFI. Partner with us to make an impactful statement across
                                            urban landscapes.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">4.</span>
                                            <span className="terms-text">
                                                  OTT Platform Partner
                                            </span>
                                        </h4>
                                        <p>Bring financial literacy to screens nationwide by streaming IFI’s engaging
                                            and educational content. Together, we can inspire audiences anytime,
                                            anywhere.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">5.</span>
                                            <span className="terms-text">
                                                  Entertainment TV Channel Partner
                                            </span>
                                        </h4>
                                        <p>Be the flagship media house that broadcasts our mission to millions. Your
                                            network will be recognized as the hub for transformative financial content
                                            that shapes India’s future.</p>

                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">6.</span>
                                            <span className="terms-text">
                                                  Radio Partner
                                            </span>
                                        </h4>
                                        <p>Amplify our message by reaching audiences during their everyday routines.
                                            Together, we’ll inspire listeners to take the first step toward financial
                                            empowerment.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">7.</span>
                                            <span className="terms-text">
                                                  Multiplex Partner
                                            </span>
                                        </h4>
                                        <p>Turn moviegoers into investors by showcasing IFI’s mission in cinemas.
                                            Partner with us to engage audiences through immersive on-screen
                                            campaigns.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">8.</span>
                                            <span className="terms-text">
                                                  Airline Partner
                                            </span>
                                        </h4>
                                        <p>Take IFI to new heights by reaching travelers and professionals during their
                                            journeys. Let’s make financial literacy part of their experience at 30,000
                                            feet.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">9.</span>
                                            <span className="terms-text">
                                                  Hotel Partner
                                            </span>
                                        </h4>
                                        <p>Partner with IFI to ensure that participants, judges, and collaborators enjoy
                                            world-class hospitality. Your brand will become synonymous with comfort,
                                            luxury, and success.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">10.</span>
                                            <span className="terms-text">
                                                  Financial News and Data Platform Partner
                                            </span>
                                        </h4>
                                        <p>Strengthen your platform’s leadership in financial insights by aligning with
                                            IFI. Together, we’ll empower individuals with the tools to make informed
                                            investment decisions.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">11.</span>
                                            <span className="terms-text">
                                                  Growth and Engagement Partner
                                            </span>
                                        </h4>
                                        <p>Expand your platform’s visibility and credibility by supporting IFI’s
                                            mission. Showcase your commitment to empowering individuals and connecting
                                            with a highly engaged audience passionate about financial success.</p>
                                    </div>
                                    <h4 style={{
                                        paddingTop: '8px',
                                        paddingBottom: '14px',
                                        fontSize: '26px',
                                        color: 'white',
                                        fontWeight: '600'
                                    }}>Join the IFI Revolution</h4>
                                    <div className="single-terms">
                                        <p>Be a part of India’s largest initiative for financial literacy and investment
                                            education. By partnering with IFI, you’ll inspire millions, transform lives,
                                            and amplify your brand's reach.</p>

                                    </div>
                                    <h4 style={{
                                        paddingTop: '8px',
                                        paddingBottom: '14px',
                                        fontSize: '26px',
                                        color: 'white',
                                        fontWeight: '600'
                                    }}>Get in Touch</h4>
                                    <div className="single-terms">
                                        <p>For partnership inquiries, please contact:</p>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Email: <a href='mailto:collaborations@indiasfutureinvestors.com'
                                                              target='_blank'>collaborations@indiasfutureinvestors.com</a>
                                                </li>
                                            </ul>

                                        </p>

                                    </div>
                                    <p>Let’s work together to build India’s future investors.</p>
                                </div>
                            </div>
                        </div>
                        {/*<hr width={{widths: '100%', borderTop: '1px solid #535353'}}/>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col-md-12 col-sm-12 col-xs-12">*/}
                        {/*        <div className="section-headline text-center">*/}
                        {/*            <h3>Partnership Enquiry Form</h3>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col-md-12 col-sm-12 col-xs-12">*/}
                        {/*        <form id="contactForm" className="contact-form"*/}
                        {/*        >*/}
                        {/*            <div className="col-md-6 col-sm-6 col-xs-12">*/}
                        {/*                <input*/}
                        {/*                    type="text" id="name" placeholder="Enter your full name"*/}
                        {/*                    name='fullName'*/}

                        {/*                    className="form-control"/>*/}

                        {/*            </div>*/}
                        {/*            <div className="col-md-6 col-sm-6 col-xs-12"><input*/}
                        {/*                type="text" id="designation" name='designation'*/}
                        {/*                placeholder="Your job title or role"*/}
                        {/*                data-error="Please enter your Designation"*/}
                        {/*                className="email form-control"/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-6 col-sm-6 col-xs-12"><input*/}
                        {/*                type="text" id="company" placeholder="Enter your company or organization name"*/}
                        {/*                name='contactNumber'*/}
                        {/*                className="form-control"/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-6 col-sm-6 col-xs-12">*/}
                        {/*                <input*/}
                        {/*                    type="email" id="email"*/}
                        {/*                    placeholder="Enter your official email address"*/}
                        {/*                    name='email'*/}
                        {/*                    className="form-control"/>*/}

                        {/*            </div>*/}
                        {/*            <div className="col-md-6 col-sm-6 col-xs-12">*/}
                        {/*                <input*/}
                        {/*                    type="text" id="phone"*/}
                        {/*                    placeholder="Enter your phone number"*/}
                        {/*                    name='phone'*/}
                        {/*                    className="form-control"/>*/}

                        {/*            </div>*/}

                        {/*            <div className="col-md-6 col-sm-6 col-xs-12">*/}
                        {/*                <select*/}
                        {/*                    className="form-control main-select" name="experience" id="cars"*/}
                        {/*                >*/}
                        {/*                    <option value="">Industry Type</option>*/}
                        {/*                    {industyType && industyType.map((data) => {*/}
                        {/*                        return <option key={data.id}*/}
                        {/*                                       value={`${data.id},${data.value}`}>{data.name}</option>;*/}
                        {/*                    })}*/}
                        {/*                </select>*/}

                        {/*            </div>*/}
                        {/*            <div className="col-md-6 col-sm-6 col-xs-12">*/}
                        {/*                <select*/}
                        {/*                    className="form-control main-select" name="experience" id="cars"*/}
                        {/*                >*/}
                        {/*                    <option value="">Type of Partnership</option>*/}
                        {/*                    {partnershipType && partnershipType.map((data) => {*/}
                        {/*                        return <option key={data.id}*/}
                        {/*                                       value={`${data.id},${data.value}`}>{data.name}</option>;*/}
                        {/*                    })}*/}
                        {/*                </select>*/}

                        {/*            </div>*/}
                        {/*            <div className="col-md-6 col-sm-6 col-xs-12">*/}
                        {/*                <select*/}
                        {/*                    className="form-control main-select" name="experience" id="cars"*/}
                        {/*                >*/}
                        {/*                    <option value="">Preferred Mode of Contact </option>*/}
                        {/*                    {mode && mode.map((data) => {*/}
                        {/*                        return <option key={data.id}*/}
                        {/*                                       value={`${data.id},${data.value}`}>{data.name}</option>;*/}
                        {/*                    })}*/}
                        {/*                </select>*/}

                        {/*            </div>*/}
                        {/*            /!*<div className="col-md-12 col-sm-12 col-xs-12">*!/*/}
                        {/*            /!*    <select {...register("investmentPhilosophy", {required: `Investment Philosophy is required!`})}*!/*/}
                        {/*            /!*            className="form-control main-select" name="investmentPhilosophy"*!/*/}
                        {/*            /!*            id="cars"*!/*/}
                        {/*            /!*            onChange={handleGrowthChange}*!/*/}
                        {/*            /!*    >*!/*/}
                        {/*            /!*        <option value="">Select Growth</option>*!/*/}
                        {/*            /!*        {investmentPhilosophy && investmentPhilosophy.map((data) => {*!/*/}
                        {/*            /!*            return <option key={data.id}*!/*/}
                        {/*            /!*                           value={`${data.id},${data.name}`}>{data.name}</option>;*!/*/}
                        {/*            /!*        })}*!/*/}
                        {/*            /!*    </select>*!/*/}
                        {/*            /!*    {!investment ?*!/*/}
                        {/*            /!*        <ErrorMsg msg={errors.investmentPhilosophy?.message}/> : ''}*!/*/}

                        {/*            /!*</div>*!/*/}

                        {/*            <div className="col-md-12 col-sm-12 col-xs-12">*/}
                        {/*                            <textarea*/}
                        {/*                                id="message"*/}
                        {/*                                placeholder="Write any specific details or questions here"*/}
                        {/*                                data-error="Write your message"*/}
                        {/*                                name='message'*/}
                        {/*                                className="form-control desc-data"/>*/}

                        {/*            </div>*/}
                        {/*            <div className="col-md-12 col-sm-12 col-xs-12">*/}
                        {/*                <button type="submit" id="submit"*/}
                        {/*                        className="contact-btn">Submit*/}
                        {/*                </button>*/}
                        {/*                <div id="msgSubmit"*/}
                        {/*                     className="h3 text-center hidden"></div>*/}
                        {/*                <div className="clearfix"></div>*/}
                        {/*            </div>*/}
                        {/*        </form>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                </div>
                <FooterMain/>
                <Footer/>
            </div>
        </>
    )
}
export default Partnership