import React from 'react'
import Navbar from "../navbar/Navbar";
import Background from "../../assets/img/background/Privacy-policy.png";
import FooterMain from "../FooterMainSection/FooterMain";
import Footer from "../footer-section/Footer";

const CsrImpact = () => {
    return (
        <>
            <div>
                <Navbar/>
                <div className="page-area" style={{backgroundImage: `url(${Background})`}}>
                    <div className="breadcumb-overlay"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb" style={{display: "block"}}>
                                    <div className="section-headline">
                                        <h2>CSR & Impact</h2>
                                    </div>
                                    <ul>
                                        <li className="home-bread">
                                            <a href="/" className="nuxt-link-active">
                                                Home
                                            </a>
                                        </li>
                                        <li>CSR & Impact</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms-area bg-color-2 area-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h3>Empowering Communities Through Financial Education</h3>
                                    <p>
                                        At India’s Future Investors (IFI), we believe financial literacy is a right, not
                                        a privilege. While participation in the show includes mandatory merchandise, our
                                        Corporate Social Responsibility (CSR) efforts focus on empowering individuals
                                        who face barriers to accessing financial education.

                                    </p>
                                    <p>Our CSR initiatives are dedicated to providing free resources, training, and
                                        mentorship to specific groups who may not otherwise have access to such
                                        opportunities.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="company-terms">
                                    <div className="single-terms">
                                        <h4><span className="number">1.</span>
                                            <span className="terms-text">
                                                   Who We Support
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <h5>Our CSR programs focus on:</h5>
                                            <ul className="ifi-list">
                                                <li>Underserved Communities
                                                    We provide free workshops and financial literacy programs in rural
                                                    and semi-urban areas where access to such education is limited.

                                                </li>
                                                <li>Women Empowerment
                                                    To bridge the gender gap in financial knowledge, we organize special
                                                    sessions for women, encouraging them to take control of their
                                                    financial futures.
                                                </li>
                                                <li>Low-Income Groups
                                                    Free training and mentorship are offered to individuals who cannot
                                                    afford paid financial education, ensuring that no one is left behind
                                                    in the journey toward financial empowerment.
                                                </li>
                                                <li>Youth from Marginalized Backgrounds
                                                    We collaborate with schools, colleges, and NGOs to introduce
                                                    investment basics to students from economically weaker sections.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">2.</span>
                                            <span className="terms-text">
                                                   How We Make an Impact
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Free Educational Workshops: Delivered by financial experts in
                                                    underserved regions.
                                                </li>
                                                <li>Scholarships for Financial Education: Covering course fees for
                                                    individuals demonstrating a strong commitment to learning.
                                                </li>
                                                <li>
                                                    Community Investment Clubs: Enabling local groups to practice
                                                    simulated investing and understand wealth-building strategies.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">3.</span>
                                            <span className="terms-text">
                                                   Our Vision
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Through targeted CSR efforts, IFI aims to create a ripple effect of
                                                    financial awareness, empowering individuals to make informed
                                                    decisions and contribute to India’s economic growth.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">4.</span>
                                            <span className="terms-text">
                                                   Get Involved in CSR
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Want to support our initiatives? Partner with us to expand financial
                                                    literacy to those who need it most.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">5.</span>
                                            <span className="terms-text">
                                                   Contact Us
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Email: <a
                                                    href='mailto:csr@indiasfutureinvestors.com'> csr@indiasfutureinvestors.com</a>
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">6.</span>
                                            <span className="terms-text">
                                                   Transparency Note:
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>
                                                    Participation in IFI for the general audience includes mandatory
                                                    merchandise, which helps fund our broader educational programs and
                                                    show operations. Our CSR efforts, however, are specifically designed
                                                    to uplift communities with limited access to financial education.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain/>
                <Footer/>

            </div>
        </>
    )
}
export default CsrImpact