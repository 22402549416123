import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import SignUp from "./pages/signup/SignUp";

function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Header/>}/>
                    <Route path="/sign-up" element={<SignUp/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
