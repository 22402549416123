import React, {useEffect, useState} from 'react';
import Logo from '../assets/img/logo/logo.gif'
import './header.css'
import {SubmitHandler, useForm} from 'react-hook-form';
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Footer from "./footer-section/Footer";
import AboutUs from "./aboutUs/aboutUs";

const ErrorMsg = ({msg}) => {
    return <div style={{color: "red"}}>{msg}</div>;
};

const Header = () => {
    const [stateData, setState] = useState(null);
    const [selectedState, setSelectedState] = useState("");
    const [stateName, setStateName] = useState('')
    const [experience, setExperience] = useState('')
    const [investment, setInvestment] = useState('')
    const investmentExperience = [
        {
            id: 1,
            name: 'Beginner',
            value: 'BEGINNER'
        },
        {
            id: 2,
            name: 'Expert',
            value: 'EXPERT'
        }
    ]
    const investmentPhilosophy = [
        {
            id: 1,
            name: 'Short Term'
        },
        {
            id: 2,
            name: 'Long Term'
        }
    ]
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm();
    const onSubmit = (data) => {
        if (data) {
            const payload = {
                fullName: data.fullName,
                emailAddress: data.email,
                contactNumber: data.contactNumber,
                city: stateName,
                message: data.message,
                investmentExperience: experience,
                investmentPhilosophy: investment,
                // videoFile:null,
            };
            const headersParam = {
                'Content-Type': 'Content-Type',
                "Accept": "*/*",
            };
            axios
                .post(
                    `https://gatewayapi-uat.worldtradingleagues.com/api/v1/ifi/register`,
                    payload,
                    {headers: headersParam}
                )
                .then((res) => {
                    toast.success('Register Successfully');
                    reset()
                })
                .catch((err) => {
                    toast.error(err.response.data.message || 'Something went wrong');
                });
        }

    };
    useEffect(() => {
        getAllState()
    }, []);
    const getAllState = () => {
        axios.get(`https://gatewayapi.worldtradingleagues.com/wtl/api/v0/states`)
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handleStateChange = (event) => {
        const selectedValue = event.target.value;
        const [id, name] = selectedValue.split(',');
        setSelectedState(id);
        setStateName(name)

    };
    const handleExperienceChange = (event) => {
        const selectedValue = event.target.value;
        const [id, name] = selectedValue.split(',');
        setExperience(name)
    }
    const handleGrowthChange = (event) => {
        const selectedValue = event.target.value;
        const [id, name] = selectedValue.split(',');
        setInvestment(name)
    }
    const handleClick = () =>{
        window.location.replace('/sign-up')
    }

    return (
        <div>
            <ToastContainer/>
            <div>
                <header className="header-one">
                    <div id="sticker" className="header-area hidden-xs">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 col-sm-3">
                                    <div className="logo">
                                        <a href="/" className="navbar-brand">
                                            <img src={Logo} alt='Logo' style={{height: '101px'}}/>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-9 col-sm-9">
                                    <div className="header-right-link">
                                        <a onClick={handleClick} className="s-menu">
                                            Sign Up
                                        </a>
                                    </div>
                                    <nav className="navbar navbar-default">
                                        <div
                                            id="navbar-example"
                                            className="collapse navbar-collapse"
                                        >
                                            <div className="main-menu">
                                                <ul
                                                    className="nav navbar-nav navbar-right"
                                                >
                                                    <li data-v-45fa8f74="">
                                                        <a href="/" className="pagess">
                                                            Home
                                                        </a>
                                                        {/*<ul className="sub-menu" data-v-45fa8f74="">*/}
                                                        {/*    <li data-v-45fa8f74="">*/}
                                                        {/*        <a*/}
                                                        {/*            href="/"*/}
                                                        {/*            aria-current="page"*/}
                                                        {/*            className="nuxt-link-exact-active nuxt-link-active"*/}
                                                        {/*            data-v-45fa8f74=""*/}
                                                        {/*        >*/}
                                                        {/*            Home 01*/}
                                                        {/*        </a>*/}
                                                        {/*    </li>*/}
                                                        {/*    <li data-v-45fa8f74="">*/}
                                                        {/*        <a href="/index-2" data-v-45fa8f74="">*/}
                                                        {/*            Home 02*/}
                                                        {/*        </a>*/}
                                                        {/*    </li>*/}
                                                        {/*    <li data-v-45fa8f74="">*/}
                                                        {/*        <a href="/index-3" data-v-45fa8f74="">*/}
                                                        {/*            Home 03*/}
                                                        {/*        </a>*/}
                                                        {/*    </li>*/}
                                                        {/*</ul>*/}
                                                    </li>
                                                    {/*<li data-v-45fa8f74="">*/}
                                                    {/*    <a href="/play" className="pagess" data-v-45fa8f74="">*/}
                                                    {/*        Play*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}
                                                    {/*<li data-v-45fa8f74="">*/}
                                                    {/*    <a href="/#" className="pagess" data-v-45fa8f74="">*/}
                                                    {/*        Games*/}
                                                    {/*    </a>*/}
                                                    {/*    <ul className="sub-menu" data-v-45fa8f74="">*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/games" data-v-45fa8f74="">*/}
                                                    {/*                All Games*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/games-details" data-v-45fa8f74="">*/}
                                                    {/*                Games Details*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*    </ul>*/}
                                                    {/*</li>*/}
                                                    {/*<li data-v-45fa8f74="">*/}
                                                    {/*    <a*/}
                                                    {/*        href="/lottery"*/}
                                                    {/*        className="pagess"*/}
                                                    {/*        data-v-45fa8f74=""*/}
                                                    {/*    >*/}
                                                    {/*        Lottery*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}
                                                    {/*<li data-v-45fa8f74="">*/}
                                                    {/*    <a href="/#" className="pagess" data-v-45fa8f74="">*/}
                                                    {/*        Pages*/}
                                                    {/*    </a>*/}
                                                    {/*    <ul className="sub-menu" data-v-45fa8f74="">*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/about" data-v-45fa8f74="">*/}
                                                    {/*                About*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/team" data-v-45fa8f74="">*/}
                                                    {/*                team*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/faq" data-v-45fa8f74="">*/}
                                                    {/*                FAQ*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/pricing" data-v-45fa8f74="">*/}
                                                    {/*                Pricing*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/login" data-v-45fa8f74="">*/}
                                                    {/*                Login*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/signup" data-v-45fa8f74="">*/}
                                                    {/*                Signup*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/error" data-v-45fa8f74="">*/}
                                                    {/*                Error*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/terms" data-v-45fa8f74="">*/}
                                                    {/*                terms &amp; Conditions*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*    </ul>*/}
                                                    {/*</li>*/}
                                                    {/*<li data-v-45fa8f74="">*/}
                                                    {/*    <a href="#" className="pagess" data-v-45fa8f74="">*/}
                                                    {/*        Blog*/}
                                                    {/*    </a>*/}
                                                    {/*    <ul className="sub-menu" data-v-45fa8f74="">*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/blog" data-v-45fa8f74="">*/}
                                                    {/*                Blog grid*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/blog-sidebar" data-v-45fa8f74="">*/}
                                                    {/*                Blog Sidebar*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        <li data-v-45fa8f74="">*/}
                                                    {/*            <a href="/blog-details" data-v-45fa8f74="">*/}
                                                    {/*                Blog Details*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*    </ul>*/}
                                                    {/*</li>*/}
                                                    {/*<li data-v-45fa8f74="">*/}
                                                    {/*    <a href="/contact" data-v-45fa8f74="">*/}
                                                    {/*        contacts*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="mobile-menu-area hidden-lg hidden-md hidden-sm"

                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mobile-menu">
                                        <div className="logo">
                                            <a href="/index">
                                                <img src={Logo} alt="Logo"/>
                                            </a>
                                        </div>
                                        <div
                                            id="menu-button"
                                            className="menu-button"
                                        >
                                            <i className="fa fa-bars"/>
                                        </div>
                                        <nav id="dropdown">
                                            <ul style={{display: "none"}}>
                                                <li>
                                                    <a href="index" className="pagess">
                                                        Home
                                                    </a>
                                                    <ul className="sub-menu">
                                                        <li>
                                                            <a href="/index">
                                                                Home 01
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/index-2">
                                                                Home 02
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/index-3">
                                                                Home 03
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li data-v-45fa8f74="">
                                                    <a href="play" className="pagess">
                                                        Play
                                                    </a>
                                                </li>
                                                <li data-v-45fa8f74="">
                                                    <a href="#" className="pagess">
                                                        Games
                                                    </a>
                                                    <ul className="sub-menu">
                                                        <li>
                                                            <a href="/games">
                                                                All Games
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/games-details">
                                                                Games Details
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li data-v-45fa8f74="">
                                                    <a href="lottery" className="pagess">
                                                        Lottery
                                                    </a>
                                                </li>
                                                <li data-v-45fa8f74="">
                                                    <a href="#" className="pagess" data-v-45fa8f74="">
                                                        Pages
                                                    </a>
                                                    <ul className="sub-menu" data-v-45fa8f74="">
                                                        <li data-v-45fa8f74="">
                                                            <a href="/about" data-v-45fa8f74="">
                                                                About
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/team" data-v-45fa8f74="">
                                                                team
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/faq" data-v-45fa8f74="">
                                                                FAQ
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/pricing" data-v-45fa8f74="">
                                                                Pricing
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/login" data-v-45fa8f74="">
                                                                Login
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/signup" data-v-45fa8f74="">
                                                                Signup
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/error" data-v-45fa8f74="">
                                                                Error
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/terms" data-v-45fa8f74="">
                                                                terms &amp; Conditions
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li data-v-45fa8f74="">
                                                    <a href="#" className="pagess" data-v-45fa8f74="">
                                                        Blog
                                                    </a>
                                                    <ul className="sub-menu" data-v-45fa8f74="">
                                                        <li data-v-45fa8f74="">
                                                            <a href="/blog" data-v-45fa8f74="">
                                                                Blog grid
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/blog-sidebar" data-v-45fa8f74="">
                                                                Blog Sidebar
                                                            </a>
                                                        </li>
                                                        <li data-v-45fa8f74="">
                                                            <a href="/blog-details" data-v-45fa8f74="">
                                                                Blog Details
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li data-v-45fa8f74="">
                                                    <a href="/contact" data-v-45fa8f74="">
                                                        contacts
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="intro-area" data-v-54c69e70="">
                    <div className="bg-wrapper" data-v-54c69e70="">
                        <img src={require('../assets/img/background/slide-bg.png')} alt=""/>
                    </div>
                    <div className="intro-content">
                        <div className="slider-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="slide-all-text text-center">
                                            <div
                                                data-wow-delay="0.3s"
                                                className="layer-1 wow fadeInUp"
                                                data-v-54c69e70=""
                                            >
                                                <h2 className="title2">
                                                    India’s Premier Investment
                                                    <span className="color">
                                                        {" "}Reality TV Show
                                                  </span>
                                                </h2>
                                            </div>
                                            <div
                                                data-wow-delay="0.5s"
                                                className="layer-2 wow fadeInUp"
                                                data-v-54c69e70=""
                                            >
                                                <p data-v-54c69e70="">
                                                    The search for India’s brightest investment minds begins here.
                                                    Registration is now open!
                                                </p>
                                            </div>
                                            <div
                                                data-wow-delay="0.7s"
                                                className="layer-3 wow fadeInUp"
                                                data-v-54c69e70=""
                                            >
                                                <a href="#" className="ready-btn" data-v-54c69e70="">
                                                    Register Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-md-6 col-sm-6 hidden-xs" data-v-54c69e70="">*/}
                                    {/*    <div*/}
                                    {/*        data-wow-delay="0.5s"*/}
                                    {/*        className="slide-images-inner wow fadeInUp"*/}
                                    {/*        data-v-54c69e70=""*/}
                                    {/*    >*/}
                                    {/*        <div className="slide-images" data-v-54c69e70="">*/}
                                    {/*            /!*<img src={require('../assets/img/slider/s1.png')} alt="" data-v-54c69e70=""/>*!/*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-area bg-color-2 fix area-padding">
                    <div className="container main-section">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-12 form-section">
                                <h2 className='mt-0'><span className="color">Champion Investor Registration</span></h2>
                                <p>Unlock Opportunities to Become India’s Next Investment Leader</p>
                                <div className="contact-form">
                                    <div className="row">
                                        <form id="contactForm" className="contact-form"
                                              onSubmit={handleSubmit(onSubmit)}>
                                            <div className="col-md-6 col-sm-6 col-xs-12"><input
                                                type="text" id="name" placeholder="Full Name"
                                                name='fullName'
                                                {...register('fullName', {
                                                    required: 'Full Name is required!',
                                                })}
                                                className="form-control"/>
                                                <ErrorMsg msg={errors.fullName?.message}/>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12"><input
                                                type="email" id="email" name='email' placeholder="Email"
                                                data-error="Please enter your email"
                                                {...register('email', {
                                                    required: 'Email is required!',
                                                })}
                                                className="email form-control"/>
                                                <ErrorMsg msg={errors.email?.message}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12"><input
                                                type="text" id="msg_subject" placeholder="Contact Number"
                                                {...register('contactNumber', {
                                                    required: 'Contact Number is required!',
                                                })}
                                                name='contactNumber'
                                                className="form-control"/>
                                                <ErrorMsg msg={errors.contactNumber?.message}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <select {...register("state", {required: `State is required!`})}
                                                        className="form-control main-select" name="state" id="cars"
                                                        onChange={handleStateChange}
                                                >
                                                    <option value="">Select State</option>
                                                    {stateData && stateData.map((data) => {
                                                        return <option key={data.id}
                                                                       value={`${data.id},${data.name}`}>{data.name}</option>;
                                                    })}
                                                </select>
                                                <ErrorMsg msg={errors.state?.message}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <select {...register("experience", {required: `Investment Experience is required!`})}
                                                        className="form-control main-select" name="experience" id="cars"
                                                        onChange={handleExperienceChange}
                                                >
                                                    <option value="">Select Experience</option>
                                                    {investmentExperience && investmentExperience.map((data) => {
                                                        return <option key={data.id}
                                                                       value={`${data.id},${data.value}`}>{data.name}</option>;
                                                    })}
                                                </select>
                                                <ErrorMsg msg={errors.experience?.message}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <select {...register("investmentPhilosophy", {required: `Investment Philosophy is required!`})}
                                                        className="form-control main-select" name="investmentPhilosophy"
                                                        id="cars"
                                                        onChange={handleGrowthChange}
                                                >
                                                    <option value="">Select Growth</option>
                                                    {investmentPhilosophy && investmentPhilosophy.map((data) => {
                                                        return <option key={data.id}
                                                                       value={`${data.id},${data.name}`}>{data.name}</option>;
                                                    })}
                                                </select>
                                                <ErrorMsg msg={errors.investmentPhilosophy?.message}/>
                                            </div>

                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <textarea
                                                id="message"
                                                placeholder="Tell Us About Your Investment Philosophy"
                                                data-error="Write your message"
                                                name='message'
                                                {...register('message', {
                                                    required: 'Message is required!',
                                                })}
                                                className="form-control desc-data"/>


                                                <ErrorMsg msg={errors.contactNumber?.message}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <button type="submit" id="submit"
                                                        className="contact-btn">Submit
                                                </button>
                                                <div id="msgSubmit"
                                                     className="h3 text-center hidden"></div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 main-section">
                                <div className='opportunity-style'>
                                    <h2 className='mt-0'><span className="color">Why Should You Participate?</span></h2>
                                    <ol style={{paddingTop: '43px'}}>
                                        <li>India's Future Investor Title : Earn the prestigious title of
                                            "India's
                                            Future Investor".
                                        </li>
                                        <li>Real Investment Opportunities : Chance to manage real portfolios and
                                            make
                                            investment decisions.
                                        </li>
                                        <li>Mentorship from Top Experts : Gain insights and guidance from
                                            industry
                                            leaders.
                                        </li>
                                        <li>Media Exposure : Share your journey with a national audience,
                                            becoming a
                                            role model for future investors.
                                        </li>
                                        <li>Prizes and Opportunities : Winners receive non-monetary rewards such
                                            as scholarship, mentorship, and even funding support for their portfolios.
                                        </li>
                                    </ol>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="achivement-area bg-color area-padding-2">
                    <div className="container main-section">
                        <div className="row">
                            <div className="achivement-content">
                                <h1>Auditions Opening Soon</h1>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="single-achive first-achive">
                                        <div className="achive-image">
                                            <img src={require('../assets/img/about/achive1.png')} alt=""
                                            />
                                        </div>
                                        <div className="achivement-text">
                  <span className="achive-number">
                    Virtual Audition
                  </span>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="single-achive second-achive">
                                        <div className="achive-image">
                                            <img src={require('../assets/img/about/achive2.png')} alt=""
                                            />
                                        </div>
                                        <div className="achivement-text">
                  <span className="achive-number">
                    Live Audition
                  </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AboutUs/>

                <div data-v-4c30a2a2="" className="how-to-area bg-color area-padding">
                    <div data-v-4c30a2a2="" className="container">
                        <div data-v-4c30a2a2="" className="row">
                            <div data-v-4c30a2a2="" className="col-md-12 col-sm-12 col-xs-12 achivement-content">
                                <div data-v-4c30a2a2="" className="section-headline text-center"><h1
                                    data-v-4c30a2a2="">How Does it Works?</h1>
                                    {/*<p data-v-4c30a2a2="">Help agencies to*/}
                                    {/*define*/}
                                    {/*their new business objectives and then create professional software.</p>*/}
                                </div>
                            </div>
                        </div>
                        <div data-v-4c30a2a2="" className="row">
                            <div data-v-4c30a2a2="" className="all-how">
                                <div data-v-4c30a2a2="" className="col-md-4 col-sm-4 col-xs-12">
                                    <div data-v-4c30a2a2="" className="single-how first-item">
                                        <div data-v-4c30a2a2="" className="how-img"><span data-v-4c30a2a2=""
                                                                                          className="h-number">01</span>
                                            <a data-v-4c30a2a2="" href="#" className="big-icon">
                                                <img data-v-4c30a2a2=""
                                                     src={require('../assets/img/about/h1.png')}
                                                     alt=""/></a></div>
                                        <div data-v-4c30a2a2="" className="how-wel">
                                            <div data-v-4c30a2a2="" className="how-content"><h4
                                                data-v-4c30a2a2="">Registration</h4> <p data-v-4c30a2a2="">
                                                Contestants start by filling out a form with their basic details and
                                                submitting a video that shares their investment journey, challenges
                                                faced, and vision for wealth creation. They can also share this video on
                                                social media and submit the post URL.
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-4c30a2a2="" className="col-md-4 col-sm-4 col-xs-12">
                                    <div data-v-4c30a2a2="" className="single-how ">
                                        <div data-v-4c30a2a2="" className="how-img"><span data-v-4c30a2a2=""
                                                                                          className="h-number">02</span>
                                            <a data-v-4c30a2a2="" href="#" className="big-icon"><img data-v-4c30a2a2=""
                                                                                                     src={require('../assets/img/about/h2.png')}
                                                                                                     alt=""/></a></div>
                                        <div data-v-4c30a2a2="" className="how-wel">
                                            <div data-v-4c30a2a2="" className="how-content"><h4
                                                data-v-4c30a2a2="">Virtual Auditions</h4><p>Selected participants from
                                                Registration will take an investment quiz that tests their knowledge of
                                                finance and long-term investing, helping judges assess their skills.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-4c30a2a2="" className="col-md-4 col-sm-4 col-xs-12">
                                    <div data-v-4c30a2a2="" className="single-how thired-item">
                                        <div data-v-4c30a2a2="" className="how-img"><span data-v-4c30a2a2=""
                                                                                          className="h-number">03</span>
                                            <a data-v-4c30a2a2="" href="#" className="big-icon"><img data-v-4c30a2a2=""
                                                                                                     src={require('../assets/img/about/h3.png')}
                                                                                                     alt=""/></a></div>
                                        <div data-v-4c30a2a2="" className="how-wel">
                                            <div data-v-4c30a2a2="" className="how-content"><h4 data-v-4c30a2a2="">Live
                                                Auditions</h4> <p data-v-4c30a2a2="">Shortlisted contestants will attend
                                                Live Auditions in various cities, where they’ll present their investment
                                                ideas and interact with the judges.
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-4c30a2a2="" className="row mt-5">
                            <div data-v-4c30a2a2="" className="all-how work-section-card">
                                <div data-v-4c30a2a2="" className="col-md-4 col-sm-4 col-xs-12">
                                    <div data-v-4c30a2a2="" className="single-how first-item">
                                        <div data-v-4c30a2a2="" className="how-img"><span data-v-4c30a2a2=""
                                                                                          className="h-number">04</span>
                                            <a data-v-4c30a2a2="" href="#" className="big-icon">
                                                <img data-v-4c30a2a2=""
                                                     src={require('../assets/img/about/h1.png')}
                                                     alt=""/></a></div>
                                        <div data-v-4c30a2a2="" className="how-wel">
                                            <div data-v-4c30a2a2="" className="how-content"><h4
                                                data-v-4c30a2a2="">Main Show</h4> <p data-v-4c30a2a2="">
                                                Contestants who pass the auditions enter the Main Show, where they’ll
                                                participate in challenges like portfolio building, market analysis, and
                                                more, designed to test their investing abilities.
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-4c30a2a2="" className="col-md-4 col-sm-4 col-xs-12">
                                    <div data-v-4c30a2a2="" className="single-how ">
                                        <div data-v-4c30a2a2="" className="how-img"><span data-v-4c30a2a2=""
                                                                                          className="h-number">05</span>
                                            <a data-v-4c30a2a2="" href="#" className="big-icon"><img data-v-4c30a2a2=""
                                                                                                     src={require('../assets/img/about/h2.png')}
                                                                                                     alt=""/></a></div>
                                        <div data-v-4c30a2a2="" className="how-wel">
                                            <div data-v-4c30a2a2="" className="how-content"><h4
                                                data-v-4c30a2a2="">Finale</h4><p>Top contestants from the Main Show will
                                                compete in the Finale, facing advanced tasks to determine who will be
                                                named one of India’s Future Investors.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*<div className="games-area bg-color area-padding" data-v-82d4d02a="">*/}
                {/*    <div className="container" data-v-82d4d02a="">*/}
                {/*        <div className="row" data-v-82d4d02a="">*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12" data-v-82d4d02a="">*/}
                {/*                <div className="section-headline text-center" data-v-82d4d02a="">*/}
                {/*                    <h3 data-v-82d4d02a="">Online games</h3>*/}
                {/*                    <p data-v-82d4d02a="">*/}
                {/*                        Dummy text is also used to demonstrate the appearance of*/}
                {/*                        different typefaces and layouts*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row" data-v-82d4d02a="">*/}
                {/*            <div className="all-games games-carousel" data-v-82d4d02a="">*/}
                {/*                <div className="swiper-container" data-v-82d4d02a="">*/}
                {/*                    <div className="swiper-wrapper">*/}
                {/*                        <div className="swiper-slide" data-v-82d4d02a="">*/}
                {/*                            <div*/}
                {/*                                className="col-md-4 col-sm-6 col-xs-12"*/}
                {/*                                data-v-82d4d02a=""*/}
                {/*                            >*/}
                {/*                                <div className="single-games" data-v-82d4d02a="">*/}
                {/*                                    <h4 data-v-82d4d02a="">Wins goal</h4>*/}
                {/*                                    <div className="games-img" data-v-82d4d02a="">*/}
                {/*                                        <img*/}
                {/*                                            src="/img/games/g1.png"*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-82d4d02a=""*/}
                {/*                                        />*/}
                {/*                                    </div>*/}
                {/*                                    {" "}*/}
                {/*                                    <a href="#" className="games-btn" data-v-82d4d02a="">*/}
                {/*                                        Play Now*/}
                {/*                                    </a>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="swiper-slide" data-v-82d4d02a="">*/}
                {/*                            <div*/}
                {/*                                className="col-md-4 col-sm-6 col-xs-12"*/}
                {/*                                data-v-82d4d02a=""*/}
                {/*                            >*/}
                {/*                                <div className="single-games" data-v-82d4d02a="">*/}
                {/*                                    <h4 data-v-82d4d02a="">Best Reward</h4>*/}
                {/*                                    <div className="games-img" data-v-82d4d02a="">*/}
                {/*                                        <img*/}
                {/*                                            src="/img/games/g2.png"*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-82d4d02a=""*/}
                {/*                                        />*/}
                {/*                                    </div>*/}
                {/*                                    {" "}*/}
                {/*                                    <a href="#" className="games-btn" data-v-82d4d02a="">*/}
                {/*                                        Play Now*/}
                {/*                                    </a>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="swiper-slide" data-v-82d4d02a="">*/}
                {/*                            <div*/}
                {/*                                className="col-md-4 col-sm-6 col-xs-12"*/}
                {/*                                data-v-82d4d02a=""*/}
                {/*                            >*/}
                {/*                                <div className="single-games" data-v-82d4d02a="">*/}
                {/*                                    <h4 data-v-82d4d02a="">Win Award</h4>*/}
                {/*                                    <div className="games-img" data-v-82d4d02a="">*/}
                {/*                                        <img*/}
                {/*                                            src="/img/games/g3.png"*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-82d4d02a=""*/}
                {/*                                        />*/}
                {/*                                    </div>*/}
                {/*                                    {" "}*/}
                {/*                                    <a href="#" className="games-btn" data-v-82d4d02a="">*/}
                {/*                                        Play Now*/}
                {/*                                    </a>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="swiper-slide" data-v-82d4d02a="">*/}
                {/*                            <div*/}
                {/*                                className="col-md-4 col-sm-6 col-xs-12"*/}
                {/*                                data-v-82d4d02a=""*/}
                {/*                            >*/}
                {/*                                <div className="single-games" data-v-82d4d02a="">*/}
                {/*                                    <h4 data-v-82d4d02a="">best win</h4>*/}
                {/*                                    <div className="games-img" data-v-82d4d02a="">*/}
                {/*                                        <img*/}
                {/*                                            src="/img/games/g4.png"*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-82d4d02a=""*/}
                {/*                                        />*/}
                {/*                                    </div>*/}
                {/*                                    {" "}*/}
                {/*                                    <a href="#" className="games-btn" data-v-82d4d02a="">*/}
                {/*                                        Play Now*/}
                {/*                                    </a>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="swiper-slide" data-v-82d4d02a="">*/}
                {/*                            <div*/}
                {/*                                className="col-md-4 col-sm-6 col-xs-12"*/}
                {/*                                data-v-82d4d02a=""*/}
                {/*                            >*/}
                {/*                                <div className="single-games" data-v-82d4d02a="">*/}
                {/*                                    <h4 data-v-82d4d02a="">Pirates of sea</h4>*/}
                {/*                                    <div className="games-img" data-v-82d4d02a="">*/}
                {/*                                        <img*/}
                {/*                                            src="/img/games/g5.png"*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-82d4d02a=""*/}
                {/*                                        />*/}
                {/*                                    </div>*/}
                {/*                                    {" "}*/}
                {/*                                    <a href="#" className="games-btn" data-v-82d4d02a="">*/}
                {/*                                        Play Now*/}
                {/*                                    </a>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="swiper-pagination bullets" data-v-82d4d02a=""/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="games-play-area bg-color-2 area-padding">*/}
                {/*    <div className="container main-section">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12">*/}
                {/*                <div className="section-headline text-center main-section-heading">*/}
                {/*                    <h3>How Does it Works?</h3>*/}
                {/*                    /!*<p data-v-152cd9be="">*!/*/}
                {/*                    /!*    Dummy text is also used to demonstrate the appearance of*!/*/}
                {/*                    /!*    different typefaces and layouts*!/*/}
                {/*                    /!*</p>*!/*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row" data-v-152cd9be="">*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12" data-v-152cd9be="">*/}
                {/*                <div className="tab-menu" data-v-152cd9be="">*/}
                {/*                    <ul*/}
                {/*                        id="myTab"*/}
                {/*                        role="tablist"*/}
                {/*                        className="nav nav-tabs"*/}
                {/*                        data-v-152cd9be=""*/}
                {/*                    >*/}
                {/*                        <li className="active" data-v-152cd9be="">*/}
                {/*                            <a*/}
                {/*                                id="one-tab"*/}
                {/*                                href="#tab-one"*/}
                {/*                                className="nav-link"*/}
                {/*                                data-v-152cd9be=""*/}
                {/*                            >*/}
                {/*                                Fierce Rem <br data-v-152cd9be=""/> BT- 0.0234*/}
                {/*                            </a>*/}
                {/*                        </li>*/}
                {/*                        <li className="nav-item" data-v-152cd9be="">*/}
                {/*                            <a*/}
                {/*                                id="two-tab"*/}
                {/*                                href="#tab-two"*/}
                {/*                                className="nav-link"*/}
                {/*                                data-v-152cd9be=""*/}
                {/*                            >*/}
                {/*                                Snacks Run <br data-v-152cd9be=""/> BT- 0.0234*/}
                {/*                            </a>*/}
                {/*                        </li>*/}
                {/*                        <li className="nav-item" data-v-152cd9be="">*/}
                {/*                            <a*/}
                {/*                                id="three-tab"*/}
                {/*                                href="#tab-three"*/}
                {/*                                className="nav-link"*/}
                {/*                                data-v-152cd9be=""*/}
                {/*                            >*/}
                {/*                                Pirates of sea <br data-v-152cd9be=""/> BT- 0.0234*/}
                {/*                            </a>*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12" data-v-152cd9be="">*/}
                {/*                <div className="tab-content" data-v-152cd9be="">*/}
                {/*                    <div*/}
                {/*                        id="tab-one"*/}
                {/*                        role="tabpanel"*/}
                {/*                        aria-labelledby="one-tab"*/}
                {/*                        className="tab-pane active"*/}
                {/*                        data-v-152cd9be=""*/}
                {/*                    >*/}
                {/*                        <div className="tab-inner" data-v-152cd9be="">*/}
                {/*                            <div className="Gaimer-zone row" data-v-152cd9be="">*/}
                {/*                                <div*/}
                {/*                                    className="col-md-4 col-sm-4 col-xs-12"*/}
                {/*                                    data-v-152cd9be=""*/}
                {/*                                >*/}
                {/*                                    <div className="gaimer-inner" data-v-152cd9be="">*/}
                {/*                                        <div className="gaimer-list" data-v-152cd9be="">*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src={require('../assets/img/icon/m1.png')}*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Andrew jonson</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src={require('../assets/img/icon/m3.png')}*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Stepen Hopper</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src={require('../assets/img/icon/m4.png')}*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Anderson</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src={require('../assets/img/icon/m5.png')}*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Janiy capri</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src={require('../assets/img/icon/m6.png')}*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Lagisha</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div*/}
                {/*                                                className="single-gaimer last-content"*/}
                {/*                                                data-v-152cd9be=""*/}
                {/*                                            >*/}
                {/*                                                <img*/}
                {/*                                                    src={require('../assets/img/icon/m7.png')}*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Ragner lorth</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                                <div*/}
                {/*                                    className="col-md-8 col-sm-8 col-xs-12"*/}
                {/*                                    data-v-152cd9be=""*/}
                {/*                                >*/}
                {/*                                    <div className="single-page" data-v-152cd9be="">*/}
                {/*                                        <div className="page-img" data-v-152cd9be="">*/}
                {/*                                            <img*/}
                {/*                                                src={require('../assets/img/games/b1.jpg')}*/}
                {/*                                                alt=""*/}
                {/*                                                data-v-152cd9be=""*/}
                {/*                                            />{" "}*/}
                {/*                                            <a href="#" className="play-btn" data-v-152cd9be="">*/}
                {/*                                                Play Now*/}
                {/*                                            </a>*/}
                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div*/}
                {/*                        id="tab-two"*/}
                {/*                        role="tabpanel"*/}
                {/*                        aria-labelledby="two-tab"*/}
                {/*                        className="tab-pane"*/}
                {/*                        data-v-152cd9be=""*/}
                {/*                    >*/}
                {/*                        <div className="tab-inner" data-v-152cd9be="">*/}
                {/*                            <div className="Gaimer-zone row" data-v-152cd9be="">*/}
                {/*                                <div*/}
                {/*                                    className="col-md-4 col-sm-4 col-xs-12"*/}
                {/*                                    data-v-152cd9be=""*/}
                {/*                                >*/}
                {/*                                    <div className="gaimer-inner" data-v-152cd9be="">*/}
                {/*                                        <div className="gaimer-list" data-v-152cd9be="">*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src={require('../assets/img/icon/m7.png')}*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Ragner lorth</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m6.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Lagisha</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m4.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Anderson</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m5.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Janiy capri</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m3.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Stepen Hopper</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div*/}
                {/*                                                className="single-gaimer last-content"*/}
                {/*                                                data-v-152cd9be=""*/}
                {/*                                            >*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m1.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Andrew jonson</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                                <div*/}
                {/*                                    className="col-md-8 col-sm-8 col-xs-12"*/}
                {/*                                    data-v-152cd9be=""*/}
                {/*                                >*/}
                {/*                                    <div className="single-page" data-v-152cd9be="">*/}
                {/*                                        <div className="page-img" data-v-152cd9be="">*/}
                {/*                                            <img*/}
                {/*                                                src="/img/games/b2.jpg"*/}
                {/*                                                alt=""*/}
                {/*                                                data-v-152cd9be=""*/}
                {/*                                            />{" "}*/}
                {/*                                            <a href="#" className="play-btn" data-v-152cd9be="">*/}
                {/*                                                Play Now*/}
                {/*                                            </a>*/}
                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div*/}
                {/*                        id="tab-three"*/}
                {/*                        role="tabpanel"*/}
                {/*                        aria-labelledby="three-tab"*/}
                {/*                        className="tab-pane"*/}
                {/*                        data-v-152cd9be=""*/}
                {/*                    >*/}
                {/*                        <div className="tab-inner" data-v-152cd9be="">*/}
                {/*                            <div className="Gaimer-zone row" data-v-152cd9be="">*/}
                {/*                                <div*/}
                {/*                                    className="col-md-4 col-sm-4 col-xs-12"*/}
                {/*                                    data-v-152cd9be=""*/}
                {/*                                >*/}
                {/*                                    <div className="gaimer-inner" data-v-152cd9be="">*/}
                {/*                                        <div className="gaimer-list" data-v-152cd9be="">*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m1.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Andrew jonson</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m3.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Stepen Hopper</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m4.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Anderson</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m5.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Janiy capri</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="single-gaimer" data-v-152cd9be="">*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m6.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Lagisha</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                            <div*/}
                {/*                                                className="single-gaimer last-content"*/}
                {/*                                                data-v-152cd9be=""*/}
                {/*                                            >*/}
                {/*                                                <img*/}
                {/*                                                    src="/img/icon/m7.png"*/}
                {/*                                                    alt=""*/}
                {/*                                                    className="gaimer"*/}
                {/*                                                    data-v-152cd9be=""*/}
                {/*                                                />*/}
                {/*                                                <div className="gaimer-content" data-v-152cd9be="">*/}
                {/*                                                    <h6 data-v-152cd9be="">Ragner lorth</h6>{" "}*/}
                {/*                                                    <span className="bit-mony" data-v-152cd9be="">*/}
                {/*                BT- 0.0234*/}
                {/*              </span>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                                <div*/}
                {/*                                    className="col-md-8 col-sm-8 col-xs-12"*/}
                {/*                                    data-v-152cd9be=""*/}
                {/*                                >*/}
                {/*                                    <div className="single-page" data-v-152cd9be="">*/}
                {/*                                        <div className="page-img" data-v-152cd9be="">*/}
                {/*                                            <img*/}
                {/*                                                src="/img/games/b3.jpg"*/}
                {/*                                                alt=""*/}
                {/*                                                data-v-152cd9be=""*/}
                {/*                                            />{" "}*/}
                {/*                                            <a href="#" className="play-btn" data-v-152cd9be="">*/}
                {/*                                                Play Now*/}
                {/*                                            </a>*/}
                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    className="payment-history-area bg-color fix area-padding"*/}
                {/*    data-v-14390bbb=""*/}
                {/*>*/}
                {/*    <div className="container" data-v-14390bbb="">*/}
                {/*        <div className="row" data-v-14390bbb="">*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12" data-v-14390bbb="">*/}
                {/*                <div className="section-headline text-center" data-v-14390bbb="">*/}
                {/*                    <h3 data-v-14390bbb="">Games history</h3>*/}
                {/*                    <p data-v-14390bbb="">*/}
                {/*                        Dummy text is also used to demonstrate the appearance of*/}
                {/*                        different typefaces and layouts*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row" data-v-14390bbb="">*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12" data-v-14390bbb="">*/}
                {/*                <div className="deposite-content" data-v-14390bbb="">*/}
                {/*                    <div className="diposite-box" data-v-14390bbb="">*/}
                {/*                        <div className="deposite-table" data-v-14390bbb="">*/}
                {/*                            <table data-v-14390bbb="">*/}
                {/*                                <tbody>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <th data-v-14390bbb="">Name</th>*/}
                {/*                                    <th data-v-14390bbb="">Code Id</th>*/}
                {/*                                    <th data-v-14390bbb="">Games</th>*/}
                {/*                                    <th data-v-14390bbb="">Level</th>*/}
                {/*                                    <th data-v-14390bbb="">Reward</th>*/}
                {/*                                    <th data-v-14390bbb="">Games ID</th>*/}
                {/*                                </tr>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <td data-v-14390bbb="">*/}
                {/*                                        <img*/}
                {/*                                            src={require('../assets/img/icon/m.png')}*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-14390bbb=""*/}
                {/*                                        />*/}
                {/*                                        Ragner Lorth*/}
                {/*                                    </td>*/}
                {/*                                    <td data-v-14390bbb="">EmI-76076</td>*/}
                {/*                                    <td data-v-14390bbb="">Redline</td>*/}
                {/*                                    <td data-v-14390bbb="">Level-122</td>*/}
                {/*                                    <td data-v-14390bbb="">00.83 BTC</td>*/}
                {/*                                    <td data-v-14390bbb="">BTC-0.02</td>*/}
                {/*                                </tr>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <td data-v-14390bbb="">*/}
                {/*                                        <img*/}
                {/*                                            src={require('../assets/img/icon/m1.png')}*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-14390bbb=""*/}
                {/*                                        />*/}
                {/*                                        Adams jems*/}
                {/*                                    </td>*/}
                {/*                                    <td data-v-14390bbb="">EmI-65342</td>*/}
                {/*                                    <td data-v-14390bbb="">Danger war</td>*/}
                {/*                                    <td data-v-14390bbb="">Level-132</td>*/}
                {/*                                    <td data-v-14390bbb="">00.83 BTC</td>*/}
                {/*                                    <td data-v-14390bbb="">BTC-0.02</td>*/}
                {/*                                </tr>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <td data-v-14390bbb="">*/}
                {/*                                        <img*/}
                {/*                                            src="/img/icon/m2.png"*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-14390bbb=""*/}
                {/*                                        />*/}
                {/*                                        Admond sayhel*/}
                {/*                                    </td>*/}
                {/*                                    <td data-v-14390bbb="">EmI-20053</td>*/}
                {/*                                    <td data-v-14390bbb="">Casino</td>*/}
                {/*                                    <td data-v-14390bbb="">Level-153</td>*/}
                {/*                                    <td data-v-14390bbb="">00.83 BTC</td>*/}
                {/*                                    <td data-v-14390bbb="">BTC-0.02</td>*/}
                {/*                                </tr>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <td data-v-14390bbb="">*/}
                {/*                                        <img*/}
                {/*                                            src={require('../assets/img/icon/m3.png')}*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-14390bbb=""*/}
                {/*                                        />*/}
                {/*                                        Jecky chen*/}
                {/*                                    </td>*/}
                {/*                                    <td data-v-14390bbb="">EmI-60754</td>*/}
                {/*                                    <td data-v-14390bbb="">Redline</td>*/}
                {/*                                    <td data-v-14390bbb="">Level-92</td>*/}
                {/*                                    <td data-v-14390bbb="">00.83 BTC</td>*/}
                {/*                                    <td data-v-14390bbb="">BTC-0.02</td>*/}
                {/*                                </tr>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <td data-v-14390bbb="">*/}
                {/*                                        <img*/}
                {/*                                            src={require('../assets/img/icon/m4.png')}*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-14390bbb=""*/}
                {/*                                        />*/}
                {/*                                        Junior mlind*/}
                {/*                                    </td>*/}
                {/*                                    <td data-v-14390bbb="">EmI-76076</td>*/}
                {/*                                    <td data-v-14390bbb="">Casino</td>*/}
                {/*                                    <td data-v-14390bbb="">Level-88</td>*/}
                {/*                                    <td data-v-14390bbb="">00.83 BTC</td>*/}
                {/*                                    <td data-v-14390bbb="">BTC-0.02</td>*/}
                {/*                                </tr>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <td data-v-14390bbb="">*/}
                {/*                                        <img*/}
                {/*                                            src={require('../assets/img/icon/m5.png')}*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-14390bbb=""*/}
                {/*                                        />*/}
                {/*                                        Andrew kolin*/}
                {/*                                    </td>*/}
                {/*                                    <td data-v-14390bbb="">EmI-76076</td>*/}
                {/*                                    <td data-v-14390bbb="">Dicks game</td>*/}
                {/*                                    <td data-v-14390bbb="">Level-184</td>*/}
                {/*                                    <td data-v-14390bbb="">00.83 BTC</td>*/}
                {/*                                    <td data-v-14390bbb="">BTC-0.02</td>*/}
                {/*                                </tr>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <td data-v-14390bbb="">*/}
                {/*                                        <img*/}
                {/*                                            src="/img/icon/m6.png"*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-14390bbb=""*/}
                {/*                                        />*/}
                {/*                                        Anjel loyel*/}
                {/*                                    </td>*/}
                {/*                                    <td data-v-14390bbb="">EmI-76076</td>*/}
                {/*                                    <td data-v-14390bbb="">Redline</td>*/}
                {/*                                    <td data-v-14390bbb="">Level-102</td>*/}
                {/*                                    <td data-v-14390bbb="">00.83 BTC</td>*/}
                {/*                                    <td data-v-14390bbb="">BTC-0.02</td>*/}
                {/*                                </tr>*/}
                {/*                                <tr data-v-14390bbb="">*/}
                {/*                                    <td data-v-14390bbb="">*/}
                {/*                                        <img*/}
                {/*                                            src="/img/icon/m7.png"*/}
                {/*                                            alt=""*/}
                {/*                                            data-v-14390bbb=""*/}
                {/*                                        />*/}
                {/*                                        Jenefer july*/}
                {/*                                    </td>*/}
                {/*                                    <td data-v-14390bbb="">EmI-76076</td>*/}
                {/*                                    <td data-v-14390bbb="">Redline</td>*/}
                {/*                                    <td data-v-14390bbb="">Level-102</td>*/}
                {/*                                    <td data-v-14390bbb="">00.83 BTC</td>*/}
                {/*                                    <td data-v-14390bbb="">BTC-0.02</td>*/}
                {/*                                </tr>*/}
                {/*                                </tbody>*/}
                {/*                            </table>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="work-proses fix bg-color area-padding-2" data-v-616e5009="">*/}
                {/*    <div className="container" data-v-616e5009="">*/}
                {/*        <div className="row" data-v-616e5009="">*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12" data-v-616e5009="">*/}
                {/*                <div className="section-headline text-center" data-v-616e5009="">*/}
                {/*                    <h3 data-v-616e5009="">Referral bonus level</h3>*/}
                {/*                    <p data-v-616e5009="">*/}
                {/*                        Help agencies to define their new business objectives and then*/}
                {/*                        create professional software.*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row" data-v-616e5009="">*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12" data-v-616e5009="">*/}
                {/*                <div className="row" data-v-616e5009="">*/}
                {/*                    <div className="work-proses-inner text-center" data-v-616e5009="">*/}
                {/*                        <div className="col-md-4 col-sm-4 col-xs-12" data-v-616e5009="">*/}
                {/*                            <div className="single-proses" data-v-616e5009="">*/}
                {/*                                <div className="proses-content" data-v-616e5009="">*/}
                {/*                                    <div*/}
                {/*                                        className="proses-icon point-blue"*/}
                {/*                                        data-v-616e5009=""*/}
                {/*                                    >*/}
                {/*        <span className="point-view" data-v-616e5009="">*/}
                {/*          01*/}
                {/*        </span>{" "}*/}
                {/*                                        <a href="#" data-v-616e5009="">*/}
                {/*                                            <i className="ti-briefcase" data-v-616e5009=""/>*/}
                {/*                                        </a>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="proses-text" data-v-616e5009="">*/}
                {/*                                        <h4 data-v-616e5009="">*/}
                {/*                                            Level 01 instant 10% Bonus reward*/}
                {/*                                        </h4>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="col-md-4 col-sm-4 col-xs-12" data-v-616e5009="">*/}
                {/*                            <div className="single-proses" data-v-616e5009="">*/}
                {/*                                <div className="proses-content" data-v-616e5009="">*/}
                {/*                                    <div*/}
                {/*                                        className="proses-icon point-orange"*/}
                {/*                                        data-v-616e5009=""*/}
                {/*                                    >*/}
                {/*        <span className="point-view" data-v-616e5009="">*/}
                {/*          02*/}
                {/*        </span>{" "}*/}
                {/*                                        <a href="#" data-v-616e5009="">*/}
                {/*                                            <i className="ti-layers" data-v-616e5009=""/>*/}
                {/*                                        </a>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="proses-text" data-v-616e5009="">*/}
                {/*                                        <h4 data-v-616e5009="">*/}
                {/*                                            Level 02 instant 20% Bonus reward*/}
                {/*                                        </h4>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="col-md-4 col-sm-4 col-xs-12" data-v-616e5009="">*/}
                {/*                            <div className="single-proses last-item" data-v-616e5009="">*/}
                {/*                                <div className="proses-content" data-v-616e5009="">*/}
                {/*                                    <div*/}
                {/*                                        className="proses-icon point-green"*/}
                {/*                                        data-v-616e5009=""*/}
                {/*                                    >*/}
                {/*        <span className="point-view" data-v-616e5009="">*/}
                {/*          03*/}
                {/*        </span>{" "}*/}
                {/*                                        <a href="#" data-v-616e5009="">*/}
                {/*                                            <i className="ti-bar-chart-alt" data-v-616e5009=""/>*/}
                {/*                                        </a>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="proses-text" data-v-616e5009="">*/}
                {/*                                        <h4 data-v-616e5009="">*/}
                {/*                                            Level 03 instant 30% Bonus reward*/}
                {/*                                        </h4>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    className="overview-area bg-color-2 fix area-padding"*/}
                {/*    data-v-7d57fe52=""*/}
                {/*>*/}
                {/*    <div className="container" data-v-7d57fe52="">*/}
                {/*        <div className="row" data-v-7d57fe52="">*/}
                {/*            <div className="col-md-6 col-sm-6 col-xs-12" data-v-7d57fe52="">*/}
                {/*                <div className="overview-content" data-v-7d57fe52="">*/}
                {/*                    <div className="overview-images" data-v-7d57fe52="">*/}
                {/*                        <img src="/img/about/ab.jpg" alt="" data-v-7d57fe52=""/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-6 col-sm-6 col-xs-12" data-v-7d57fe52="">*/}
                {/*                <div className="overview-text" data-v-7d57fe52="">*/}
                {/*                    <h3 data-v-7d57fe52="">*/}
                {/*                        Playing games winning instant award and Bonus{" "}*/}
                {/*                    </h3>*/}
                {/*                    <p data-v-7d57fe52="">*/}
                {/*                        Replacing a maintains the amount of lines. When replacing a*/}
                {/*                        selection. help agencies to define their new business objectives*/}
                {/*                        and then create*/}
                {/*                    </p>*/}
                {/*                    <ul data-v-7d57fe52="">*/}
                {/*                        <li data-v-7d57fe52="">*/}
                {/*                            <a href="#" data-v-7d57fe52="">*/}
                {/*                                Innovation idea latest business tecnology*/}
                {/*                            </a>*/}
                {/*                        </li>*/}
                {/*                        <li data-v-7d57fe52="">*/}
                {/*                            <a href="#" data-v-7d57fe52="">*/}
                {/*                                Digital content marketing online clients plateform*/}
                {/*                            </a>*/}
                {/*                        </li>*/}
                {/*                        <li data-v-7d57fe52="">*/}
                {/*                            <a href="#" data-v-7d57fe52="">*/}
                {/*                                Safe secure services for you online email account*/}
                {/*                            </a>*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                    {" "}*/}
                {/*                    <a href="#" className="overview-btn" data-v-7d57fe52="">*/}
                {/*                        Signup today*/}
                {/*                    </a>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="blog-area fix bg-color area-padding-2" data-v-03dd537e="">*/}
                {/*    <div className="container" data-v-03dd537e="">*/}
                {/*        <div className="row" data-v-03dd537e="">*/}
                {/*            <div className="col-md-12 col-sm-12 col-xs-12" data-v-03dd537e="">*/}
                {/*                <div className="section-headline text-center" data-v-03dd537e="">*/}
                {/*                    <h3 data-v-03dd537e="">Games world news</h3>*/}
                {/*                    <p data-v-03dd537e="">*/}
                {/*                        Dummy text is also used to demonstrate the appearance of*/}
                {/*                        different typefaces and layouts*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row" data-v-03dd537e="">*/}
                {/*            <div className="blog-grid home-blog" data-v-03dd537e="">*/}
                {/*                <div className="col-md-4 col-sm-6 col-xs-12" data-v-03dd537e="">*/}
                {/*                    <div className="single-blog" data-v-03dd537e="">*/}
                {/*                        <div className="blog-image" data-v-03dd537e="">*/}
                {/*                            <a href="#" className="image-scale" data-v-03dd537e="">*/}
                {/*                                <img src="/img/blog/b1.jpg" alt="" data-v-03dd537e=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="blog-content" data-v-03dd537e="">*/}
                {/*                            <div className="blog-meta" data-v-03dd537e="">*/}
                {/*    <span className="admin-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-user" data-v-03dd537e=""/>*/}
                {/*      Admin*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="date-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-calendar" data-v-03dd537e=""/>*/}
                {/*      20 july, 2019*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="comments-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-comment-o" data-v-03dd537e=""/>*/}
                {/*      13*/}
                {/*    </span>*/}
                {/*                            </div>*/}
                {/*                            <a href="#" data-v-03dd537e="">*/}
                {/*                                <h4 data-v-03dd537e="">*/}
                {/*                                    Creative games design clients response is better*/}
                {/*                                </h4>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-4 col-sm-6 col-xs-12" data-v-03dd537e="">*/}
                {/*                    <div className="single-blog" data-v-03dd537e="">*/}
                {/*                        <div className="blog-content" data-v-03dd537e="">*/}
                {/*                            <div className="blog-meta" data-v-03dd537e="">*/}
                {/*    <span className="admin-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-user" data-v-03dd537e=""/>*/}
                {/*      Admin*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="date-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-calendar" data-v-03dd537e=""/>*/}
                {/*      13 may, 2018*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="comments-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-comment-o" data-v-03dd537e=""/>*/}
                {/*      16*/}
                {/*    </span>*/}
                {/*                            </div>*/}
                {/*                            <a href="#" data-v-03dd537e="">*/}
                {/*                                <h4 data-v-03dd537e="">*/}
                {/*                                    Games development is a best work in future world*/}
                {/*                                </h4>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="blog-image" data-v-03dd537e="">*/}
                {/*                            <a href="#" className="image-scale" data-v-03dd537e="">*/}
                {/*                                <img src="/img/blog/b2.jpg" alt="" data-v-03dd537e=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-4 col-sm-6 col-xs-12" data-v-03dd537e="">*/}
                {/*                    <div className="single-blog" data-v-03dd537e="">*/}
                {/*                        <div className="blog-image" data-v-03dd537e="">*/}
                {/*                            <a href="#" className="image-scale" data-v-03dd537e="">*/}
                {/*                                <img src="/img/blog/b3.jpg" alt="" data-v-03dd537e=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="blog-content" data-v-03dd537e="">*/}
                {/*                            <div className="blog-meta" data-v-03dd537e="">*/}
                {/*    <span className="admin-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-user" data-v-03dd537e=""/>*/}
                {/*      Admin*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="date-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-calendar" data-v-03dd537e=""/>*/}
                {/*      24 april, 2019*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="comments-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-comment-o" data-v-03dd537e=""/>*/}
                {/*      07*/}
                {/*    </span>*/}
                {/*                            </div>*/}
                {/*                            <a href="#" data-v-03dd537e="">*/}
                {/*                                <h4 data-v-03dd537e="">*/}
                {/*                                    You can trust me and playing with best win*/}
                {/*                                </h4>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-4 col-sm-6 col-xs-12" data-v-03dd537e="">*/}
                {/*                    <div className="single-blog" data-v-03dd537e="">*/}
                {/*                        <div className="blog-content" data-v-03dd537e="">*/}
                {/*                            <div className="blog-meta" data-v-03dd537e="">*/}
                {/*    <span className="admin-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-user" data-v-03dd537e=""/>*/}
                {/*      Admin*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="date-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-calendar" data-v-03dd537e=""/>*/}
                {/*      28 june, 2019*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="comments-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-comment-o" data-v-03dd537e=""/>*/}
                {/*      32*/}
                {/*    </span>*/}
                {/*                            </div>*/}
                {/*                            <a href="#" data-v-03dd537e="">*/}
                {/*                                <h4 data-v-03dd537e="">*/}
                {/*                                    Games playerwant to be benifit pateform games.*/}
                {/*                                </h4>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="blog-image" data-v-03dd537e="">*/}
                {/*                            <a href="#" className="image-scale" data-v-03dd537e="">*/}
                {/*                                <img src="/img/blog/b4.jpg" alt="" data-v-03dd537e=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-4 col-sm-6 col-xs-12" data-v-03dd537e="">*/}
                {/*                    <div className="single-blog" data-v-03dd537e="">*/}
                {/*                        <div className="blog-image" data-v-03dd537e="">*/}
                {/*                            <a href="#" className="image-scale" data-v-03dd537e="">*/}
                {/*                                <img src="/img/blog/b5.jpg" alt="" data-v-03dd537e=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="blog-content" data-v-03dd537e="">*/}
                {/*                            <div className="blog-meta" data-v-03dd537e="">*/}
                {/*    <span className="admin-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-user" data-v-03dd537e=""/>*/}
                {/*      Admin*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="date-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-calendar" data-v-03dd537e=""/>*/}
                {/*      28 june, 2019*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="comments-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-comment-o" data-v-03dd537e=""/>*/}
                {/*      32*/}
                {/*    </span>*/}
                {/*                            </div>*/}
                {/*                            <a href="#" data-v-03dd537e="">*/}
                {/*                                <h4 data-v-03dd537e="">*/}
                {/*                                    Games player man want to be win any game*/}
                {/*                                </h4>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-4 col-sm-6 col-xs-12" data-v-03dd537e="">*/}
                {/*                    <div className="single-blog" data-v-03dd537e="">*/}
                {/*                        <div className="blog-content" data-v-03dd537e="">*/}
                {/*                            <div className="blog-meta" data-v-03dd537e="">*/}
                {/*    <span className="admin-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-user" data-v-03dd537e=""/>*/}
                {/*      Admin*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="date-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-calendar" data-v-03dd537e=""/>*/}
                {/*      28 june, 2019*/}
                {/*    </span>{" "}*/}
                {/*                                <span className="comments-type" data-v-03dd537e="">*/}
                {/*      <i className="fa fa-comment-o" data-v-03dd537e=""/>*/}
                {/*      32*/}
                {/*    </span>*/}
                {/*                            </div>*/}
                {/*                            <a href="#" data-v-03dd537e="">*/}
                {/*                                <h4 data-v-03dd537e="">*/}
                {/*                                    Games developer must complete thier*/}
                {/*                                </h4>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="blog-image" data-v-03dd537e="">*/}
                {/*                            <a href="#" className="image-scale" data-v-03dd537e="">*/}
                {/*                                <img src="/img/blog/b6.jpg" alt="" data-v-03dd537e=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="payment-area bg-color" data-v-33b08f69="">*/}
                {/*    <div className="container" data-v-33b08f69="">*/}
                {/*        <div className="row" data-v-33b08f69="">*/}
                {/*            <div className="inner-payment" data-v-33b08f69="">*/}
                {/*                <div className="col-md-12 col-sm-12 col-xs-12" data-v-33b08f69="">*/}
                {/*                    <div className="section-headline text-center" data-v-33b08f69="">*/}
                {/*                        <h3 data-v-33b08f69="">Games Partner</h3>*/}
                {/*                        <p data-v-33b08f69="">*/}
                {/*                            Dummy text is also used to demonstrate the appearance of*/}
                {/*                            different typefaces and layouts*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-12 col-sm-12 col-xs-12" data-v-33b08f69="">*/}
                {/*                    <div className="card-list" data-v-33b08f69="">*/}
                {/*                        <div className="single-card" data-v-33b08f69="">*/}
                {/*                            <a href="#" data-v-33b08f69="">*/}
                {/*                                <img src="/img/brand/1.png" alt="" data-v-33b08f69=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="single-card" data-v-33b08f69="">*/}
                {/*                            <a href="#" data-v-33b08f69="">*/}
                {/*                                <img src="/img/brand/2.png" alt="" data-v-33b08f69=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="single-card" data-v-33b08f69="">*/}
                {/*                            <a href="#" data-v-33b08f69="">*/}
                {/*                                <img src="/img/brand/3.png" alt="" data-v-33b08f69=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="single-card" data-v-33b08f69="">*/}
                {/*                            <a href="#" data-v-33b08f69="">*/}
                {/*                                <img src="/img/brand/4.png" alt="" data-v-33b08f69=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        <div className="single-card" data-v-33b08f69="">*/}
                {/*                            <a href="#" data-v-33b08f69="">*/}
                {/*                                <img src="/img/brand/5.png" alt="" data-v-33b08f69=""/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div data-v-903b52d0="">
                    <footer className="footer-1" data-v-903b52d0="">
                        {/*<div className="footer-area" data-v-903b52d0="">*/}
                        {/*    <div className="container" data-v-903b52d0="">*/}
                        {/*        <div className="row" data-v-903b52d0="">*/}
                        {/*            <div className="col-md-4 col-sm-6 col-xs-12" data-v-903b52d0="">*/}
                        {/*                <div className="footer-content logo-footer" data-v-903b52d0="">*/}
                        {/*                    <div className="footer-head" data-v-903b52d0="">*/}
                        {/*                        <div className="footer-logo" data-v-903b52d0="">*/}
                        {/*                            <a*/}
                        {/*                                href="#"*/}
                        {/*                                className="footer-black-logo"*/}
                        {/*                                data-v-903b52d0=""*/}
                        {/*                            >*/}
                        {/*                                <img*/}
                        {/*                                    src={Logo}*/}
                        {/*                                    alt=""*/}
                        {/*                                    data-v-903b52d0=""*/}
                        {/*                                    style={{width: '70%'}}*/}
                        {/*                                />*/}
                        {/*                            </a>*/}
                        {/*                        </div>*/}
                        {/*                        <p data-v-903b52d0="">*/}
                        {/*                            Replacing a maintains the amount of lines. When replacing*/}
                        {/*                            a selection. help agencies to define their new business*/}
                        {/*                            objectives and then create. Replacing a maintains the*/}
                        {/*                            amount of lines.*/}
                        {/*                        </p>*/}
                        {/*                        <div className="subs-feilds" data-v-903b52d0="">*/}
                        {/*                            <div className="suscribe-input" data-v-903b52d0="">*/}
                        {/*                                <input*/}
                        {/*                                    type="email"*/}
                        {/*                                    id="sus_email"*/}
                        {/*                                    placeholder="Type Email"*/}
                        {/*                                    className="email form-control width-80"*/}
                        {/*                                    data-v-903b52d0=""*/}
                        {/*                                />{" "}*/}
                        {/*                                <button*/}
                        {/*                                    type="submit"*/}
                        {/*                                    id="sus_submit"*/}
                        {/*                                    className="add-btn"*/}
                        {/*                                    data-v-903b52d0=""*/}
                        {/*                                >*/}
                        {/*                                    Subscribe*/}
                        {/*                                </button>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*<div className="col-md-2 col-sm-3 col-xs-12" data-v-903b52d0="">*!/*/}
                        {/*            /!*    <div className="footer-content" data-v-903b52d0="">*!/*/}
                        {/*            /!*        <div className="footer-head" data-v-903b52d0="">*!/*/}
                        {/*            /!*            <h4 data-v-903b52d0="">Games list</h4>*!/*/}
                        {/*            /!*            <ul className="footer-list" data-v-903b52d0="">*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Dicks game*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Lobester*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Gangstar*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Royal Casino*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Align fight*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Black night*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*            </ul>*!/*/}
                        {/*            /!*        </div>*!/*/}
                        {/*            /!*    </div>*!/*/}
                        {/*            /!*</div>*!/*/}
                        {/*            /!*<div className="col-md-2 col-sm-3 col-xs-12" data-v-903b52d0="">*!/*/}
                        {/*            /!*    <div className="footer-content" data-v-903b52d0="">*!/*/}
                        {/*            /!*        <div className="footer-head" data-v-903b52d0="">*!/*/}
                        {/*            /!*            <h4 data-v-903b52d0="">Company</h4>*!/*/}
                        {/*            /!*            <ul className="footer-list" data-v-903b52d0="">*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        About us*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Services{" "}*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Events*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Promotion*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Transition*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Social Media*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*            </ul>*!/*/}
                        {/*            /!*        </div>*!/*/}
                        {/*            /!*    </div>*!/*/}
                        {/*            /!*</div>*!/*/}
                        {/*            /!*<div className="col-md-2 hidden-sm col-xs-12" data-v-903b52d0="">*!/*/}
                        {/*            /!*    <div className="footer-content" data-v-903b52d0="">*!/*/}
                        {/*            /!*        <div className="footer-head" data-v-903b52d0="">*!/*/}
                        {/*            /!*            <h4 data-v-903b52d0="">Account</h4>*!/*/}
                        {/*            /!*            <ul className="footer-list" data-v-903b52d0="">*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Ripple coin*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Bitcoin*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Ethireum*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Light coin*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Coin base*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Gold Coin*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*            </ul>*!/*/}
                        {/*            /!*        </div>*!/*/}
                        {/*            /!*    </div>*!/*/}
                        {/*            /!*</div>*!/*/}
                        {/*            /!*<div className="col-md-2 hidden-sm col-xs-12" data-v-903b52d0="">*!/*/}
                        {/*            /!*    <div className="footer-content last-content" data-v-903b52d0="">*!/*/}
                        {/*            /!*        <div className="footer-head" data-v-903b52d0="">*!/*/}
                        {/*            /!*            <h4 data-v-903b52d0="">Support</h4>*!/*/}
                        {/*            /!*            <ul className="footer-list" data-v-903b52d0="">*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Customer Care*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Live chat*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Notification*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Privacy*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Terms &amp; Condition*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*                <li data-v-903b52d0="">*!/*/}
                        {/*            /!*                    <a href="#" data-v-903b52d0="">*!/*/}
                        {/*            /!*                        Contact us{" "}*!/*/}
                        {/*            /!*                    </a>*!/*/}
                        {/*            /!*                </li>*!/*/}
                        {/*            /!*            </ul>*!/*/}
                        {/*            /!*        </div>*!/*/}
                        {/*            /!*    </div>*!/*/}
                        {/*            /!*</div>*!/*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <Footer/>
                    </footer>
                    <a
                        id="scrollUp"
                        href="#"
                        style={{position: "fixed", zIndex: 2147483647, display: "none"}}

                    >
                        <i className="ti-arrow-up"/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Header;
