import React, {useState} from 'react';
import background from '../../assets/img/background/bread-bg2.jpg';
import Navbar from '../navbar/Navbar';
import FooterMain from "../FooterMainSection/FooterMain";
import Footer from "../footer-section/Footer";

const Faq = () => {
    const [activeAccordion, setActiveAccordion] = useState(null);

    const toggleAccordion = (id) => {
        setActiveAccordion(activeAccordion === id ? null : id);
    };

    return (
        <>
            <Navbar/>
            <div>
                <div className="page-area" style={{backgroundImage: `url(${background})`}}>
                    <div className="breadcumb-overlay"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb">
                                    <div className="section-headline">
                                        <h2>FAQ</h2>
                                    </div>
                                    <ul>
                                        <li className="home-bread main-bread">
                                            <a href="/" className="nuxt-link-active">
                                                Home
                                            </a>
                                        </li>
                                        <li>FAQ</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="faq-page bg-color-2 area-padding">
                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-sm-10 col-xs-12">
                                <div className="faq-full">
                                    {/* Static accordion item 1 */}
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 1}
                                            onClick={() => toggleAccordion(1)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            What is India's Future Investors (IFI)?
                                            <span>{activeAccordion === 1 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 1 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 1 ? '10px 0' : '0',
                                                }}
                                            >
                                                India's Future Investors (IFI) is India's first-ever
                                                stock market reality show, blending financial
                                                education with entertainment. The show aims to
                                                identify and empower a new generation of informed
                                                investors by providing a platform for participants
                                                to showcase their financial journeys, gain expert
                                                mentorship, and compete for the title of India’s
                                                Future Investor.
                                            </p>
                                        </div>
                                    </div>

                                    {/* Static accordion item 2 */}
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 2}
                                            onClick={() => toggleAccordion(2)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Who can participate in the IFI show?
                                            <span>{activeAccordion === 2 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 2 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 2 ? '10px 0' : '0',
                                                }}
                                            >
                                                The show is open to both Indian residents and foreign nationals between
                                                the ages of 18 and 60. Participants must provide valid identification
                                                proof (such as Aadhaar Card, PAN Card, or Passport) and meet other
                                                eligibility criteria specified on the website.
                                            </p>
                                        </div>
                                    </div>

                                    {/* Static accordion item 3 */}
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 3}
                                            onClick={() => toggleAccordion(3)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            How can I participate in the show?
                                            <span>{activeAccordion === 3 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 3 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 3 ? '10px 0' : '0',
                                                }}
                                            >
                                                <p>To participate, you need to:</p>
                                                <ol>
                                                    <li><b>Registration:</b> Fill out the form with your details and age
                                                        proof
                                                        to get access to the IFI Dashboard.
                                                    </li>
                                                    <li><b>Open Audition:</b> Wear the official IFI T-shirt, create a
                                                        video,
                                                        share it on social media, and submit the link on the IFI
                                                        Dashboard. Impress the public and jury to move forward!
                                                    </li>
                                                    <li><b>Virtual Audition:</b> If shortlisted, you’ll take an online
                                                        quiz to
                                                        showcase your knowledge.
                                                    </li>
                                                    <li><b>Live Audition:</b> Based on quiz scores and video stories,
                                                        selected
                                                        participants will attend auditions in major cities.
                                                    </li>
                                                    <li><b>Main Show:</b> Contestants who pass auditions enter the Main
                                                        Show,
                                                        participating in challenges like portfolio building and market
                                                        analysis.
                                                    </li>
                                                    <li><b>Final Round:</b> Top contestants from the Main Show face
                                                        advanced tasks in the Finale to be named one of India’s Future
                                                        Investors.
                                                    </li>
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 4}
                                            onClick={() => toggleAccordion(4)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Is there a fee to participate in the show?
                                            <span>{activeAccordion === 4 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 4 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 4 ? '10px 0' : '0',
                                                }}
                                            >
                                                Yes, a mandatory purchase of official IFI merchandise (such as T-shirts)
                                                is required to participate in the auditions. For other costs related to
                                                the application process, please refer to the <span><a
                                                href='/terms-conditions' target="_blank"> Terms & Conditions</a></span>.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 5}
                                            onClick={() => toggleAccordion(5)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            How are winners selected in the show?
                                            <span>{activeAccordion === 5 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 5 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 5 ? '10px 0' : '0',
                                                }}
                                            >
                                                Winners are determined based on a combination of judges' evaluations and
                                                viewers' votes. Criteria include video stories, quiz scores, portfolio
                                                design skills, and challenge performance. Final rankings will be based
                                                on these assessments. India’s Future Investors is not responsible for
                                                any disputes related to rankings or voting results.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 3}
                                            onClick={() => toggleAccordion(6)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Can I apply more than once to the quiz?
                                            <span>{activeAccordion === 6 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 6 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 6 ? '10px 0' : '0',
                                                }}
                                            >
                                                You can apply for the quiz multiple times, but reapplication fees will
                                                apply for each additional attempt. For each reattempt of the quiz, you
                                                must purchase new IFI merchandise. For more details, refer to the <span><a
                                                href='/terms-conditions' target="_blank">Terms
                                                & Conditions </a></span>on our website.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 7}
                                            onClick={() => toggleAccordion(7)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Is there live trading or simulation trading in the show?
                                            <span>{activeAccordion === 7 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 7 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 7 ? '10px 0' : '0',
                                                }}
                                            >
                                                No, there is no live trading or simulated trading in the India’s Future
                                                Investors show. The show is focused on financial education, knowledge,
                                                and showcasing participants’ financial journeys without engaging in
                                                actual or simulated trading activities.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 8}
                                            onClick={() => toggleAccordion(8)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            What happens if I miss the audition?
                                            <span>{activeAccordion === 8 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 8 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 8 ? '10px 0' : '0',
                                                }}
                                            >
                                                If you miss the live audition or are unable to attend your scheduled
                                                city, you may be disqualified. We encourage all participants to review
                                                their audition city and schedule promptly.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 9}
                                            onClick={() => toggleAccordion(9)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            How is my personal data handled?
                                            <span>{activeAccordion === 9 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 9 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 9 ? '10px 0' : '0',
                                                }}
                                            >
                                                Your personal data will be handled in accordance with our <span><a
                                                href="/privacy-policy" target="_blank"> Privacy
                                                Policy</a></span>. We may share your information with our trusted
                                                partners,
                                                affiliates, and sponsors for the purposes of show-related operations and
                                                promotional activities. We will never sell or rent your personal
                                                information to third parties without your consent.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 10}
                                            onClick={() => toggleAccordion(10)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            What happens if I have a complaint or issue with my merchandise?
                                            <span>{activeAccordion === 10 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 10 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 10 ? '10px 0' : '0',
                                                }}
                                            >
                                                If you receive defective or incorrect merchandise, please contact our
                                                customer support team within 7 days of receiving the product. You can
                                                read more about our Merchandise Return and Refund Policy in the <span><a
                                                href='/terms-conditions' target="_blank"> Terms &
                                                Conditions</a></span>.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 11}
                                            onClick={() => toggleAccordion(11)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            What should I do if I have technical issues with the website or app?
                                            <span>{activeAccordion === 11 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 11 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 11 ? '10px 0' : '0',
                                                }}
                                            >
                                                If you experience any issues while navigating the website or using the
                                                IFI App, you can reach our Technical Support team by emailing
                                                support@indiasfutureinvestors.com. We strive to resolve technical issues
                                                promptly.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 12}
                                            onClick={() => toggleAccordion(12)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Will I be notified of updates about the show?
                                            <span>{activeAccordion === 12 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 12 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 12 ? '10px 0' : '0',
                                                }}
                                            >
                                                Yes! If you’ve opted in for communications, we will send updates about
                                                the show, new episodes, and important announcements via email or other
                                                communication channels. You can unsubscribe at any time by following the
                                                unsubscribe link in our emails.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 13}
                                            onClick={() => toggleAccordion(13)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Is this show for educational purposes only?
                                            <span>{activeAccordion === 13 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 13 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 13 ? '10px 0' : '0',
                                                }}
                                            >
                                                Yes, India's Future Investors is an educational platform that aims to
                                                increase financial literacy. We do not guarantee any financial profits
                                                or investment success to participants, nor are we responsible for any
                                                losses, financial or otherwise, that may occur due to participation in
                                                the show.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 14}
                                            onClick={() => toggleAccordion(14)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            How can I contact the IFI team?
                                            <span>{activeAccordion === 14 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 14 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 14 ? '10px 0' : '0',
                                                }}
                                            >
                                                If you have any additional questions or need support, feel free to visit
                                                our <spam><a href="/contact-us" target="_blank">Contact Us</a></spam> page for more
                                                information on how to reach our team via
                                                email or phone.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 15}
                                            onClick={() => toggleAccordion(15)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Can I be a sponsor or partner for the show?
                                            <span>{activeAccordion === 15 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 15 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 15 ? '10px 0' : '0',
                                                }}
                                            >
                                                Yes, we welcome potential sponsors and partners. Please visit the
                                                <span><a href="/partnership" target="_blank"> Partnerships </a></span> and <span><a
                                                href='#'> Sponsorships</a></span> section of the <span><a
                                                href='/contact-us' target="_blank">Contact Us</a></span> page to get in
                                                touch with our team for more details.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 16}
                                            onClick={() => toggleAccordion(16)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            How can I become an Official Influencer Partner of the show?
                                            <span>{activeAccordion === 16 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 16 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 16 ? '10px 0' : '0',
                                                }}
                                            >
                                                Fill out the Influencer Registration Form on our website. Our team will
                                                review your profile and reach out if you're a good fit. For details,
                                                visit our Official Influencer Partner Program page.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 17}
                                            onClick={() => toggleAccordion(17)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            What are the requirements to become an Official Influencer Partner?
                                            <span>{activeAccordion === 17 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 17 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 17 ? '10px 0' : '0',
                                                }}
                                            >
                                                You need at least 10,000 followers on social media, active engagement in
                                                finance or business topics, and a commitment to promoting financial
                                                literacy.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-faq" style={{margin: '15px 0'}}>
                                        <h2
                                            tabIndex={0}
                                            className="accordion__title-text"
                                            role="tab"
                                            aria-expanded={activeAccordion === 18}
                                            onClick={() => toggleAccordion(18)}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                background: '#26253f',
                                                color: '#fff',
                                                padding: '16px',
                                                fontWeight: '500',
                                                fontSize: '17px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            What benefits do I get as an Official Influencer Partner?
                                            <span>{activeAccordion === 18 ? '−' : '+'}</span>
                                        </h2>
                                        <div
                                            className="accordion__content"
                                            role="tabpanel"
                                            style={{
                                                maxHeight: activeAccordion === 18 ? '200px' : '0',
                                                overflow: 'hidden',
                                                transition: 'max-height 0.5s ease',
                                            }}
                                        >
                                            <p
                                                className="accordion__content-desc"
                                                style={{
                                                    padding: activeAccordion === 18 ? '10px 0' : '0',
                                                }}
                                            >
                                                You’ll earn revenue through referral programs and sponsorships, gain
                                                visibility on multiple platforms, get exclusive content, VIP access to
                                                events, and receive special IFI merchandise.
                                                <p className='mt-2'>For more info, visit our Official Influencer Partner
                                                    Program
                                                    page.</p>
                                            </p>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain/>
                <Footer/>
            </div>
        </>
    );
};

export default Faq;


