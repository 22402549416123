import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="footer-area-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="copyright">
                                <p data-v-903b52d0="">
                                    Copyright © 2024{" "}
                                    <a href="#">
                                        India Future Investors{" "}
                                    </a>{" "}
                                    All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer