import React from 'react'
// import Logo from "../../assets/img/logo/logom2.gif";
// import Bg from "../../assets/img/background/bread-bg.jpg";
import Navbar from "../navbar/Navbar";
import FooterMain from "../FooterMainSection/FooterMain";
import Footer from "../footer-section/Footer";
import Background from "../../assets/img/background/1.png";

const Sponsorship = () => {
    return (
        <>
            <div>
                <Navbar/>
                <div className="page-area" style={{backgroundImage: `url(${Background})`}}>
                    <div className="breadcumb-overlay"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb" style={{display: "block"}}>
                                    <div className="section-headline">
                                        <h2>Sponsorship</h2>
                                    </div>
                                    <ul>
                                        <li className="home-bread">
                                            <a href="/" className="nuxt-link-active">
                                                Home
                                            </a>
                                        </li>
                                        <li>Sponsorship</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms-area bg-color-2 area-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h3>Sponsorship Opportunities with India's Future Investors</h3>
                                    <p>
                                        India’s Future Investors (IFI) welcomes sponsorship from industries across all
                                        sectors. Align your brand with India’s premier financial literacy and investment
                                        education platform to enhance visibility, engage diverse audiences, and drive
                                        impactful change.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="company-terms">
                                    <h4 style={{
                                        paddingTop: '8px',
                                        paddingBottom: '24px',
                                        fontSize: '26px',
                                        color: 'white',
                                        fontWeight: '600'
                                    }}>Categories of Sponsorship</h4>
                                    <div className="single-terms">
                                        <h4><span className="number">1.</span>
                                            <span className="terms-text">
                                                  Title Sponsor
                                            </span>
                                        </h4>
                                        <p>Take centre stage as the exclusive Title Sponsor of India's Future Investors.
                                            Your brand will be featured prominently across all platforms, ensuring
                                            maximum visibility and association with this transformative initiative.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">2.</span>
                                            <span className="terms-text">
                                                  Presenting Sponsor
                                            </span>
                                        </h4>
                                        <p>Be recognized as the main presenting partner of the show. This category
                                            ensures strong branding integration across TV, digital, and on-ground touch
                                            points.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">3.</span>
                                            <span className="terms-text">
                                                  Co-Presenting Sponsor
                                            </span>
                                        </h4>
                                        <p>Collaborate with us as a Co-Presenting Sponsor, gaining extensive exposure
                                            across key promotional and broadcast channels.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">4.</span>
                                            <span className="terms-text">
                                                  Powered By Sponsor
                                            </span>
                                        </h4>
                                        <p>Fuel the success of India's Future Investors by becoming a “Powered By”
                                            Sponsor. This category guarantees significant branding and audience
                                            reach.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">5.</span>
                                            <span className="terms-text">
                                                  Co-Powered By Sponsor
                                            </span>
                                        </h4>
                                        <p>Join as a Co-Powered By Sponsor to share the spotlight with other premium
                                            brands, ensuring considerable visibility.</p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">6.</span>
                                            <span className="terms-text">
                                                  Associate/Category Sponsors
                                            </span>
                                        </h4>
                                        <p>Position your brand as a strategic partner in one of our focus categories,
                                            such as:</p>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Finance & Banking</li>
                                                <li>Technology & IT Solutions</li>
                                                <li>Media & Entertainment</li>
                                                <li>Travel & Hospitality</li>
                                                <li>Health & Wellness</li>
                                                <li>FMCG & Retail</li>
                                                <li>Education & EdTech</li>
                                                <li>E-Commerce & Startups</li>
                                            </ul>
                                        </p>
                                        <p>We are open to industry-specific sponsorships and will work with your brand
                                            to customize the integration that aligns with your goals.</p>
                                        <h4 style={{
                                            paddingTop: '8px',
                                            paddingBottom: '12px',
                                            fontSize: '26px',
                                            color: 'white',
                                            fontWeight: '600'
                                        }}>Why Sponsor IFI?</h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li><b>Unmatched Visibility:</b> Gain exposure to millions of viewers
                                                    across television, OTT, radio, and online platforms.
                                                </li>
                                                <li><b>Nationwide Impact:</b> Showcase your commitment to education and
                                                    financial literacy by associating with a transformative initiative.
                                                </li>
                                                <li><b>Access to a Premium Audience:</b> IFI attracts a cream audience
                                                    comprising millions of investors with good purchasing power,
                                                    decision-making capabilities, and strong consumer influence—making
                                                    them the perfect target audience for your brand.
                                                </li>
                                                <li><b>Customized Branding:</b> Tailored sponsorship packages ensure
                                                    your brand’s unique objectives are met.
                                                </li>
                                                <li><b>Cross-Platform Presence:</b> Engage with audiences through TV
                                                    broadcasts, live events, digital promotions, and on-ground
                                                    activations.
                                                </li>
                                            </ul>
                                        </p>
                                        <h4 style={{
                                            paddingTop: '8px',
                                            paddingBottom: '14px',
                                            fontSize: '26px',
                                            color: 'white',
                                            fontWeight: '600'
                                        }}>Sponsor with IFI</h4>
                                        <div className="single-terms">
                                            <p>Make your mark as a key enabler of India’s financial revolution. Join
                                                hands with India's Future Investors and become a driving force behind
                                                the creation of the next generation of smart investors.</p>
                                        </div>
                                        <h4 style={{
                                            paddingTop: '8px',
                                            paddingBottom: '14px',
                                            fontSize: '26px',
                                            color: 'white',
                                            fontWeight: '600'
                                        }}>Get in Touch</h4>
                                        <div className="single-terms">
                                            <p>For sponsorship opportunities, please contact:</p>
                                            <p className='terms-condition-style'>
                                                <ul className="ifi-list">
                                                    <li>Email: <a href='mailto:collaborations@indiasfutureinvestors.com' target='_blank'>collaborations@indiasfutureinvestors.com</a>
                                                    </li>
                                                </ul>

                                            </p>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain/>
                <Footer/>
            </div>
        </>
)
}
export default Sponsorship;