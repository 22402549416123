import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./header.css";
import Footer from "./footer-section/Footer";
import AboutUs from "./aboutUs/aboutUs";
import Work from "./howDoesItWork/Work";
import OpeningSoon from "./opening-soon/OpeningSoon";
import Navbar from "./navbar/Navbar";
import FooterMain from "./FooterMainSection/FooterMain";

const ErrorMsg = ({msg}) => {
    return <div style={{color: "red"}}>{msg}</div>;
};

const Header = () => {
    const [stateData, setState] = useState(null);

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
        watch,
    } = useForm();

    const onSubmit = (data) => {
        if (data) {
            const payload = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                confirmPassword: data.confirmPassword,
            };
            const headersParam = {
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
            };
            axios
                .post(
                    `https://gatewayapi-uat.worldtradingleagues.com/api/v1/ifi/register`,
                    payload,
                    {headers: headersParam}
                )
                .then((res) => {
                    toast.success("Register Successfully");
                    reset();
                })
                .catch((err) => {
                    toast.error(err.response?.data?.message || "Something went wrong");
                });
        }
    };

    useEffect(() => {
        getAllState();
    }, []);

    const getAllState = () => {
        axios
            .get(`https://gatewayapi.worldtradingleagues.com/wtl/api/v0/states`)
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div>
            <ToastContainer/>
            <div>
                <Navbar/>
                <div className="intro-area" data-v-54c69e70="">
                    <div className="bg-wrapper" data-v-54c69e70="">
                        <img
                            src={require("../assets/img/background/slide-bg.png")}
                            alt=""
                        />
                    </div>
                    <div className="intro-content">
                        <div className="slider-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="slide-all-text text-center">
                                            <div
                                                data-wow-delay="0.3s"
                                                className="layer-1 wow fadeInUp"
                                                data-v-54c69e70=""
                                            >
                                                <h2 className="title2">
                                                    India’s Premier Investment
                                                    <span className="color"> Reality TV Show</span>
                                                </h2>
                                            </div>
                                            <div
                                                data-wow-delay="0.5s"
                                                className="layer-2 wow fadeInUp"
                                                data-v-54c69e70=""
                                            >
                                                <p data-v-54c69e70="">
                                                    The search for India’s brightest investment minds
                                                    begins here. Registration is now open!
                                                </p>
                                            </div>
                                            <div
                                                data-wow-delay="0.7s"
                                                className="layer-3 wow fadeInUp"
                                                data-v-54c69e70=""
                                            >
                                                <a href="#" className="ready-btn" data-v-54c69e70="">
                                                    Register Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-area bg-color-2 fix area-padding">
                    <div className="container main-section">
                        <h1 className='text-center text-white' style={{fontWeight: '700', textAlign: 'center', marginTop: '0', paddingBottom: '40px', color: 'white'}}>Why Should You Participate?</h1>
                        <div className="row">

                            <div className="col-md-6 col-sm-12 col-xs-12 main-section">
                                {/*<span className="color">Why Should You Participate?</span>*!/*/}
                                <div className="opportunity-style overview-text mt-0">
                                    <div className="company-terms">
                                        <div className="single-terms">
                                            <p className='terms-condition-style'>
                                                <h5>1. India's Future Investor Title :</h5>
                                                <p style={{paddingLeft: '20px'}}>Earn the prestigious title of "India's
                                                    Future Investor"</p>

                                            </p>
                                            <p className='terms-condition-style'>
                                                <h5>2. Real Investment Opportunities :</h5>
                                                <p style={{paddingLeft: '20px'}}>Chance to
                                                    manage real portfolios and make investment decisions.</p>

                                            </p>
                                            <p className='terms-condition-style'>
                                                <h5>3. Mentorship from Top Experts :</h5>
                                                <p style={{paddingLeft: '20px'}}>Gain insights
                                                    and guidance from industry leaders.</p>
                                            </p>
                                            <p className='terms-condition-style'>
                                                <h5>4. Media Exposure :</h5>
                                                <p style={{paddingLeft: '20px'}}>Share your journey with a national
                                                    audience, becoming a role
                                                    model for future investors.</p>
                                            </p>
                                            <p className='terms-condition-style'>
                                                <h5>5. Prizes and Opportunities :</h5>
                                                <p style={{paddingLeft: '20px'}}>Winners receive non-monetary rewards
                                                    such as scholarships, mentorship, and even funding support for their
                                                    portfolios</p>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12 form-section">
                                <div className="contact-form">
                                    <div className="row">
                                        <form
                                            id="contactForm"
                                            className="contact-form"
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    placeholder="First Name"
                                                    name="firstName"
                                                    {...register("firstName", {
                                                        required: "First Name is required!",
                                                    })}
                                                    className="form-control"
                                                />
                                                <ErrorMsg msg={errors.firstName?.message}/>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    placeholder="Last Name"
                                                    name="lastName"
                                                    {...register("lastName", {
                                                        required: "Last Name is required!",
                                                    })}
                                                    className="form-control"
                                                />
                                                <ErrorMsg msg={errors.lastName?.message}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    {...register("email", {
                                                        required: "Email is required!",
                                                    })}
                                                    className="email form-control"
                                                />
                                                <ErrorMsg msg={errors.email?.message}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    {...register("password", {
                                                        required: "Password is required!",
                                                        minLength: {
                                                            value: 6,
                                                            message:
                                                                "Password must be at least 6 characters long",
                                                        },
                                                    })}
                                                    className="email form-control"
                                                />
                                                <ErrorMsg msg={errors.password?.message}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <input
                                                    type="password"
                                                    id="ConfirmPassword"
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    {...register("confirmPassword", {
                                                        required: "Confirm Password is required!",
                                                        validate: (value) =>
                                                            value === watch("password") ||
                                                            "Passwords do not match",
                                                    })}
                                                    className="email form-control"
                                                />
                                                <ErrorMsg msg={errors.confirmPassword?.message}/>
                                            </div>
                                            {/*<div className="col-md-12 col-sm-12 col-xs-12">*/}
                                            {/*    <input type='checkbox'/> <span className='text-white'>Remember Me</span>*/}
                                            {/*</div>*/}
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <button
                                                        type="submit"
                                                        id="submit"
                                                        className="contact-btn"
                                                    >
                                                        Submit
                                                    </button>
                                                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                    <div className="clearfix"></div>
                                                </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OpeningSoon/>
                <AboutUs/>
                <Work/>
                <FooterMain/>
                <Footer/>
            </div>
        </div>
    );
};

export default Header;
