import React from 'react'
// import Img1 from '../../assets/img/about/About_us.png'
// import Img2 from '../../assets/img/about/ab-icon.png'
// import Img3 from '../../assets/img/about/ab-icon2.png'
// import Img4 from '../../assets/img/about/ab-icon6.png'
import Vid from '../../assets/img/about/Abouto.mp4'

const AboutUs = () => {
    return (
        <>
            <div className="about-area bg-color-2 fix area-padding">
                <div className="container main-section main-section-heading">
                    <h3 className='text-center' style={{fontSize: '40px'}}>About Us</h3>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="about-content">
                                <div className="about-images">
                                    {/*<img src={Img1} alt=""/>*/}
                                    <div className="about-images-video mt-5">
                                        <video autoPlay muted loop>
                                            <source src={Vid} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="support-all main-about-section main-about-section-2">
                                <div data-v-7d57fe52="" className="overview-text"><h3>India's Future
                                    Investors<br/> is a trailblazing TV show </h3>
                                    <p data-v-7d57fe52="">IFI is on a mission
                                        to discover and shape the next generation of smart investors. Participants from
                                        across India will showcase their skills in long-term investing, market analysis,
                                        and
                                        portfolio strategy. <p className='mt-4'>More than just a competition, it’s a
                                            platform to learn, grow,
                                            and master the art of wealth creation through investing. Guided by top
                                            financial
                                            experts, contestants will gain invaluable knowledge to build a strong
                                            financial
                                            future.</p>
                                    </p>
                                    <a data-v-7d57fe52="" href="#" className="overview-btn">Registration</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUs