import React from 'react'

const Work = () => {
    return (
        <>
            <div className="how-to-area bg-color area-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 achivement-content">
                            <div className="section-headline text-center"><h1>How Does it Works?</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="all-how">
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-how first-item">
                                    <div className="how-img"><span className="h-number">01</span>
                                        <a href="#" className="big-icon">
                                            <img src={require('../../assets/img/about/Registration.png')} alt=""/></a></div>
                                    <div className="how-wel">
                                        <div className="how-content"><h4>Registration</h4> <p>
                                            Complete the registration form with your details and age proof to get your
                                            login credentials. Access the IFI Dashboard and start your journey to
                                            greatness!
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-how ">
                                    <div className="how-img"><span
                                        className="h-number">02</span>
                                        <a href="#" className="big-icon"><img
                                            src={require('../../assets/img/about/Open.png')}
                                            alt=""/></a></div>
                                    <div className="how-wel">
                                        <div className="how-content"><h4>Open Auditions</h4><p>Create a video wearing the
                                            official IFI T-shirt, upload it on your social media, and submit the link on
                                            the IFI Dashboard. If your story wins the hearts of the public and jury,
                                            you’ll advance to the next stage!.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-how ">
                                    <div className="how-img"><span className="h-number">03</span>
                                        <a href="#" className="big-icon"><img
                                            src={require('../../assets/img/about/Quiz.png')} alt=""/></a></div>
                                    <div className="how-wel">
                                        <div className="how-content"><h4>Virtual Auditions</h4><p>Selected participants
                                            from
                                            Registration will take an investment quiz that tests their knowledge of
                                            finance and long-term investing, helping judges assess their skills.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="all-how work-section-card">
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-how thired-item">
                                    <div className="how-img"><span className="h-number">04</span>
                                        <a href="#" className="big-icon"><img
                                            src={require('../../assets/img/about/Live.png')}
                                            alt=""/></a></div>
                                    <div className="how-wel">
                                        <div className="how-content"><h4>Live
                                            Auditions</h4> <p>Shortlisted contestants will attend
                                            Live Auditions in various cities, where they’ll present their investment
                                            ideas and interact with the judges.
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-how first-item">
                                    <div className="how-img"><span
                                        className="h-number">05</span>
                                        <a href="#" className="big-icon">
                                            <img
                                                src={require('../../assets/img/about/main.png')}
                                                alt=""/></a></div>
                                    <div className="how-wel">
                                        <div className="how-content"><h4
                                        >Main Show</h4> <p>
                                            Contestants who clear the auditions advance to the Main Show, Qualifiers
                                            face challenges like portfolio building and market analysis, testing their
                                            investing skills.
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-how ">
                                    <div className="how-img"><span
                                        className="h-number">06</span>
                                        <a href="#" className="big-icon"><img
                                            src={require('../../assets/img/about/Final.png')}
                                            alt=""/></a></div>
                                    <div className="how-wel">
                                        <div className="how-content"><h4>Finale</h4><p>Top contestants from the Main
                                            Show will
                                            compete in the Finale, facing advanced tasks to determine who will be
                                            named one of India’s Future Investors.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Work