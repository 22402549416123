import React, {useEffect, useState} from 'react'
import axios from "axios";
import {SubmitHandler, useForm} from 'react-hook-form';
import {toast, ToastContainer} from "react-toastify";

const ErrorMsg = ({msg}) => {
    return <div style={{color: "red",marginBottom:'10px'}}>{msg}</div>;
};

const SignUp = () => {
    const [stateData, setState] = useState(null);
    const [selectedState, setSelectedState] = useState("");
    const [stateName, setStateName] = useState('')
    const [experience, setExperience] = useState('')
    const [investment, setInvestment] = useState('')
    const investmentExperience = [
        {
            id: 1,
            name: 'Beginner',
            value: 'BEGINNER'
        },
        {
            id: 2,
            name: 'Expert',
            value: 'EXPERT'
        }
    ]
    const investmentPhilosophy = [
        {
            id: 1,
            name: 'Short Term'
        },
        {
            id: 2,
            name: 'Long Term'
        }
    ]
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm();
    const onSubmit = (data) => {
        if (data) {
            const payload = {
                fullName: data.fullName,
                emailAddress: data.email,
                contactNumber: data.contactNumber,
                city: stateName,
                message: data.message,
                investmentExperience: experience,
                investmentPhilosophy: investment,
                // videoFile:null,
            };
            const headersParam = {
                'Content-Type': 'multipart/form-data',
                "Accept": "*/*",
            };
            axios
                .post(
                    `https://gatewayapi-uat.worldtradingleagues.com/api/v1/ifi/register`,
                    payload,
                    {headers: headersParam}
                )
                .then((res) => {
                    toast.success('Register Successfully');
                    reset()
                    window.location.replace('/')
                })
                .catch((err) => {
                    console.log('ee ',err.response)
                    toast.error(err.response.data || 'Something went wrong');
                });
        }

    };
    const handleStateChange = (event) => {
        const selectedValue = event.target.value;
        const [id, name] = selectedValue.split(',');
        setSelectedState(id);
        setStateName(name)

    };
    const handleExperienceChange = (event) => {
        const selectedValue = event.target.value;
        const [id, name] = selectedValue.split(',');
        setExperience(name)
    }
    const handleGrowthChange = (event) => {
        const selectedValue = event.target.value;
        const [id, name] = selectedValue.split(',');
        setInvestment(name)
    }
    useEffect(() => {
        getAllState()
    }, []);
    const getAllState = () => {
        axios.get(`https://gatewayapi.worldtradingleagues.com/wtl/api/v0/states`)
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <>
            <div data-v-0df058b0="" className="login-area area-padding fix">
                <ToastContainer/>
                <div data-v-0df058b0="" className="login-overlay"/>
                {" "}
                <div data-v-0df058b0="" className="table">
                    <div data-v-0df058b0="" className="table-cell">
                        <div data-v-0df058b0="" className="container">
                            <div data-v-0df058b0="" className="row">
                                <div className="col-md-offset-2 col-md-8 col-sm-offset-3 col-sm-6 col-xs-12">
                                    <div className="login-form">
                                        <h4 data-v-0df058b0="" className="login-title text-center">SIGN UP</h4>
                                        <div data-v-0df058b0="" className="row">
                                            <form id="contactForm" className="log-form"  onSubmit={handleSubmit(onSubmit)}>
                                                <div className="col-md-6 col-sm-6 col-xs-12"><input
                                                    type="text" id="name" placeholder="Full Name"
                                                    name='fullName'
                                                    {...register('fullName', {
                                                        required: 'Full Name is required!',
                                                    })}
                                                    className="form-control"/>
                                                    <ErrorMsg msg={errors.fullName?.message}/>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-xs-12"><input
                                                    type="email" id="email" name='email' placeholder="Email"
                                                    {...register('email', {
                                                        required: 'Email is required!',
                                                    })}
                                                    className="email form-control"/>
                                                    <ErrorMsg msg={errors.email?.message}/>
                                                </div>
                                                <div
                                                    className="col-md-6 col-sm-6 col-xs-12"
                                                >
                                                    <input
                                                        type="text"
                                                        id="text"
                                                        name='contactNumber'
                                                        {...register('contactNumber', {
                                                            required: 'Contact Number is required!',
                                                        })}
                                                        placeholder="Contact Number"
                                                        className="form-control"
                                                    />
                                                     <ErrorMsg msg={errors.contactNumber?.message}/>
                                                </div>
                                                <div
                                                    className="col-md-6 col-sm-12 col-xs-12 main-f"
                                                >
                                                    <select
                                                        {...register("state", {required: `State is required!`})}
                                                        className="form-control main-select-f"
                                                        style={{marginTop: '0px', height: '50px'}}
                                                        name="state" id="cityMain"
                                                        onChange={handleStateChange}

                                                    >
                                                        <option value="">Select State</option>
                                                        {stateData && stateData.map((data) => {
                                                            return <option key={data.id}
                                                                           value={`${data.id},${data.name}`}>{data.name}</option>;
                                                        })}
                                                    </select>
                                                    <ErrorMsg msg={errors.state?.message}/>
                                                </div>
                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12"
                                                >
                                                    <select
                                                        {...register("experience", {required: `Investment Experience is required!`})}
                                                        className="form-control main-select-f" style={{marginTop: '0px'}}
                                                        name="experience" id="cityMain"
                                                        onChange={handleExperienceChange}

                                                    >
                                                        <option value="">Select Experience</option>
                                                    {investmentExperience && investmentExperience.map((data) => {
                                                        return <option key={data.id}
                                                                       value={`${data.id},${data.value}`}>{data.name}</option>;
                                                    })}
                                                    </select>
                                                    <ErrorMsg msg={errors.experience?.message}/>
                                                </div>
                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12"
                                                >
                                                    <select {...register("investmentPhilosophy", {required: `Investment Philosophy is required!`})}
                                                            className="form-control main-select-f"
                                                            name="investmentPhilosophy"
                                                            id="cars"
                                                            onChange={handleGrowthChange}
                                                    >
                                                        <option value="">Select Growth</option>
                                                        {investmentPhilosophy && investmentPhilosophy.map((data) => {
                                                            return <option key={data.id}
                                                                           value={`${data.id},${data.name}`}>{data.name}</option>;
                                                        })}
                                                    </select>
                                                    <ErrorMsg msg={errors.investmentPhilosophy?.message}/>
                                                </div>
                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12"
                                                >
                                                     <textarea
                                                         id="message"
                                                         placeholder="Tell Us About Your Investment Philosophy"
                                                         data-error="Write your message"
                                                         name='message'
                                                         {...register('message', {
                                                             required: 'Message is required!',
                                                         })}
                                                         className="form-control desc-data"/>


                                                    <ErrorMsg msg={errors.contactNumber?.message}/>
                                                </div>
                                                <div
                                                    data-v-0df058b0=""
                                                    className="col-md-12 col-sm-12 col-xs-12 text-center"
                                                >
                                                    <button
                                                        data-v-0df058b0=""
                                                        type="submit"
                                                        id="submit"
                                                        className="slide-btn login-btn"
                                                    >
                                                    Login
                                                    </button>

                                                    <div
                                                        data-v-0df058b0=""
                                                        id="msgSubmit"
                                                        className="h3 text-center hidden"
                                                    />

                                                    <div data-v-0df058b0="" className="clearfix"/>
                                                </div>
                                                {" "}
                                                <div
                                                    data-v-0df058b0=""
                                                    className="col-md-12 col-sm-12 col-xs-12 text-center"
                                                >
                                                    <div data-v-0df058b0="" className="clear"/>
                                                    {" "}
                                                    {/*<div data-v-0df058b0="" className="separetor text-center">*/}
                                                    {/*    <span data-v-0df058b0="">Or with Sign</span>*/}
                                                    {/*</div>*/}

                                                    {/*<div data-v-0df058b0="" className="sign-icon">*/}
                                                    {/*    <ul data-v-0df058b0="">*/}
                                                    {/*        <li data-v-0df058b0="">*/}
                                                    {/*            <a data-v-0df058b0="" href="#" className="facebook">*/}
                                                    {/*                Facebook*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        {" "}*/}
                                                    {/*        <li data-v-0df058b0="">*/}
                                                    {/*            <a data-v-0df058b0="" href="#" className="twitter">*/}
                                                    {/*                twitter*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*        {" "}*/}
                                                    {/*        <li data-v-0df058b0="">*/}
                                                    {/*            <a data-v-0df058b0="" href="#" className="google">*/}
                                                    {/*                google+*/}
                                                    {/*            </a>*/}
                                                    {/*        </li>*/}
                                                    {/*    </ul>*/}
                                                    {/*    */}
                                                    {/*</div>*/}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default SignUp;