import React from 'react'
import Img1 from '../../assets/img/about/ab.jpg'
import Img2 from '../../assets/img/about/ab-icon.png'
import Img3 from '../../assets/img/about/ab-icon2.png'
import Img4 from '../../assets/img/about/ab-icon6.png'

const AboutUs = () => {
    return (
        <>
            <div className="about-area bg-color-2 fix area-padding">
                <div className="container main-section main-section-heading">
                    <h3 className='text-center' style={{fontSize: '40px'}}>About Us</h3>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="about-content">
                                <div className="about-images">
                                    <img src={Img1} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="support-all main-about-section">
                                <div className="support-services">
                                    <img src={Img2} alt=""
                                         className="support-images"/>
                                    <div className="support-content">
                                        <p>
                                            India's Future Investors is a ground breaking TV show aimed at
                                            discovering and nurturing the next generation of smart investors.
                                        </p>
                                    </div>
                                </div>
                                <div className="support-services">
                                    <img
                                        src={Img3}
                                        alt=""
                                        className="support-images"
                                    />
                                    <div className="support-content">
                                        <p>
                                            Participants from all over India will compete through various stages,
                                            demonstrating their knowledge of long-term investing, market analysis,
                                            and portfolio management.
                                            This initiative goes beyond a typical show—it’s an opportunity to learn,
                                            grow, and build a strong foundation in the world of finance.
                                        </p>
                                    </div>
                                </div>
                                <div className="support-services last-content">
                                    <img src={Img4} alt=""
                                         className="support-images"/>
                                    <div className="support-content">
                                        <p>
                                            The show is designed for aspiring investors who believe in creating
                                            wealth through investing rather than trading. With the guidance of top
                                            financial experts and the opportunity to manage real portfolios,
                                            contestants will develop skills that can shape their financial future
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUs