import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import SignUp from "./pages/signup/SignUp";
// import Dashboard from "./pages/dashboard";
import Partnership from "./components/partnership";
import Sponsorship from "./components/Sponsorship/Sponsorship";
import Faq from "./components/faq/Faq";
import TermsCondition from "./components/TermsAndConditions/terms-condition";
// import 'bootstrap/dist/css/bootstrap.min.css'
import PrivacyPolicy from "./components/privacy-policy/privacy-policy";
// import ContactUs from "./components/contact-us/ContactUs";
import CsrImpact from "./components/csr-impact/csr-impact";
import TeamCaption from "./components/team-caption/team-caption";
import ApplyJudges from "./components/apply-judges/apply-judges";


function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Header/>}/>
                    <Route path="/sign-up" element={<SignUp/>}/>
                    {/*<Route path="/dashboard" element={<Dashboard/>}/>*/}
                    <Route path="/partnership" element={<Partnership/>}/>
                    <Route path="/sponsorship" element={<Sponsorship/>}/>
                    <Route path="/faq" element={<Faq/>}/>
                    <Route path="/terms-conditions" element={<TermsCondition/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    {/*<Route path="/contact-us" element={<ContactUs/>}/>*/}
                    <Route path="/csr-impact" element={<CsrImpact/>}/>
                    <Route path="/team-captions" element={<TeamCaption/>}/>
                    <Route path="/apply-judges" element={<ApplyJudges/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
