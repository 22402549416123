import React from 'react'
import Navbar from "../navbar/Navbar";
import Background from "../../assets/img/background/Privacy-policy.png";
import FooterMain from "../FooterMainSection/FooterMain";
import Footer from "../footer-section/Footer";

const ApplyJudges = () => {
    return (
        <>
            <div>
                <Navbar/>
                <div className="page-area" style={{backgroundImage: `url(${Background})`}}>
                    <div className="breadcumb-overlay"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb" style={{display: "block"}}>
                                    <div className="section-headline">
                                        <h2>Apply for Judges </h2>
                                    </div>
                                    <ul>
                                        <li className="home-bread">
                                            <a href="/" className="nuxt-link-active">
                                                Home
                                            </a>
                                        </li>
                                        <li>Apply for Judges</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms-area bg-color-2 area-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h3>Be a Judge on India’s Future Investors</h3>
                                    <p>
                                        Shape the future of India's investment landscape! As a judge on India’s Future Investors, your expertise and insights will empower aspiring investors, driving them toward financial literacy and success. Join us to inspire, educate, and leave a lasting legacy in the world of investing.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="company-terms">
                                    <div className="single-terms">
                                        <h4><span className="number">1.</span>
                                            <span className="terms-text">
                                                   Why Become a Judge?
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li><b>Be a Changemaker:</b> Lead India’s first investment-focused reality show and influence the nation’s financial growth.

                                                </li>
                                                <li><b>National Recognition:</b> Establish yourself as a thought leader in the finance industry.
                                                </li>
                                                <li><b>Network with Industry Leaders:</b> Collaborate with top experts, participants, and celebrities in the financial world.
                                                </li>
                                                <li><b>Impact the Next Generation:</b> Help shape India’s smartest and most successful investors.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">2.</span>
                                            <span className="terms-text">
                                                   Eligibility Criteria
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>A minimum of 10 years of professional experience in finance or investing.
                                                </li>
                                                <li>Special preference given to:
                                                    <ul>
                                                        <li>Fund Managers in AMCs, Hedge Funds, or AIFs.
                                                        </li>
                                                        <li>C-level Executives in stock broking, Investment firms, financial services.
                                                        </li>
                                                        <li>SEBI-registered RAs (Research Analysts) or RIAs (Registered Investment Advisors).</li>
                                                        <li>Heads of Research with a proven record of impactful insights.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    A proven track record of wealth creation through investments.
                                                </li>
                                                <li>Strong communication and mentoring skills.</li>
                                                <li>Demonstrated expertise in stock markets, investment strategies, or financial planning.
                                                </li>
                                                <li>A passion for fostering financial literacy and empowerment.</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">3.</span>
                                            <span className="terms-text">
                                                   Application Process
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li><b>Step 1:</b> Fill out the application form below.</li>
                                                <li><b>Step 2:</b> Submit your portfolio, achievements, and a short video on why you wish to be a judge.
                                                </li>
                                                <li><b>Step 3:</b> Shortlisted candidates will be invited for an interview with the selection panel.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain/>
                <Footer/>

            </div>
        </>
    )
}
export default ApplyJudges