import React from 'react'
import Navbar from "../navbar/Navbar";
import Background from "../../assets/img/background/Privacy-policy.png";
import FooterMain from "../FooterMainSection/FooterMain";
import Footer from "../footer-section/Footer";

const PrivacyPolicy = () => {
    return (
        <>
            <div>
                <Navbar/>
                <div className="page-area" style={{backgroundImage: `url(${Background})`}}>
                    <div className="breadcumb-overlay"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="breadcrumb" style={{display: "block"}}>
                                    <div className="section-headline">
                                        <h2>Privacy Policy</h2>
                                    </div>
                                    <ul>
                                        <li className="home-bread">
                                            <a href="/" className="nuxt-link-active">
                                                Home
                                            </a>
                                        </li>
                                        <li>Privacy Policy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms-area bg-color-2 area-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h3>Privacy Policy</h3>
                                    <p>
                                        At India's Future Investors, we are committed to protecting your privacy and
                                        ensuring that your personal information is handled securely and responsibly.
                                        This Privacy Policy outlines how we collect, use, and protect the data you
                                        provide while using our website and services. By accessing or using our website
                                        and app, you agree to this Privacy Policy.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="company-terms">
                                    <div className="single-terms">
                                        <h4><span className="number">1.</span>
                                            <span className="terms-text">
                                                  Information We Collect
                                            </span>
                                        </h4>
                                        <p>We may collect the following types of information when you use our website or
                                            services:</p>
                                        <p className='terms-condition-style'>
                                            <h5>1.1 Personal Information :</h5>
                                            <ul className="ifi-list">
                                                <li>Name, email address, phone number, postal address, and other details
                                                    provided during registration or purchases.
                                                </li>
                                            </ul>
                                            <h5>1.2 Payment Information :</h5>
                                            <ul className="ifi-list">
                                                <li>Payment details such as transaction IDs (we do not store complete
                                                    credit/debit card or banking details).
                                                </li>
                                            </ul>
                                            <h5>1.3 Usage Information :</h5>
                                            <ul className="ifi-list">
                                                <li>Data about your interaction with the website and app, including IP
                                                    address, browser type, pages visited, and duration of visits.
                                                </li>
                                            </ul>
                                            <h5>1.4 Cookies :</h5>
                                            <ul className="ifi-list">
                                                <li>Cookies and similar technologies to enhance your browsing
                                                    experience, such as remembering preferences and tracking user
                                                    activity.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">2.</span>
                                            <span className="terms-text">
                                                  How We Use Your Information
                                            </span>
                                        </h4>
                                        <p>We use the information collected for the following purposes:</p>
                                        <p className='terms-condition-style'>
                                            <h5>2.1 To Provide Services :</h5>
                                            <ul className="ifi-list">
                                                <li>Facilitate registrations, purchases, returns, and refunds.
                                                </li>
                                            </ul>
                                            <h5>2.2 To Improve User Experience :</h5>
                                            <ul className="ifi-list">
                                                <li>Analyze website and app usage and user behaviour to enhance our
                                                    services.
                                                </li>
                                            </ul>
                                            <h5>2.3 Marketing and Communications :</h5>
                                            <ul className="ifi-list">
                                                <li>Send promotional materials, updates about the show, or offers if you
                                                    opt-in to receive such communications.
                                                </li>
                                            </ul>
                                            <h5>2.4 Compliance and Legal Obligations :</h5>
                                            <ul className="ifi-list">
                                                <li>Comply with applicable laws, regulations, and security requirements,
                                                    including the Information Technology (Reasonable Security Practices
                                                    and Procedures and Sensitive Personal Data or Information) Rules,
                                                    2011.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">3.</span>
                                            <span className="terms-text">
                                                  Sharing of Information
                                            </span>
                                        </h4>
                                        <p>We do not sell or rent your personal information to third parties. However,
                                            we may share your information in the following circumstances:</p>
                                        <p className='terms-condition-style'>
                                            <h5>3.1 Service Providers :</h5>
                                            <ul className="ifi-list">
                                                <li>With trusted third-party vendors to process payments, deliver
                                                    merchandise, and support website and app operations.
                                                </li>
                                            </ul>
                                            <h5>3.2 Legal Requirements :</h5>
                                            <ul className="ifi-list">
                                                <li>When required by law, court order, or to protect our legal rights.
                                                </li>
                                            </ul>
                                            <h5>3.3 Business Transfers :</h5>
                                            <ul className="ifi-list">
                                                <li>In the event of a merger, acquisition, or sale of assets, your
                                                    information may be transferred as part of the business.
                                                </li>
                                            </ul>
                                            <h5>3.4 Partners and Sponsors :</h5>
                                            <ul className="ifi-list">
                                                <li>We may share your information with our partners and sponsors for
                                                    promotional purposes or to facilitate show-related activities,
                                                    subject to your consent when required.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">4.</span>
                                            <span className="terms-text">
                                                  Data Security
                                            </span>
                                        </h4>
                                        <p>We take reasonable precautions to protect your data:</p>
                                        <p className='terms-condition-style'>
                                            <h5>4.1 Encryption :</h5>
                                            <ul className="ifi-list">
                                                <li>Secure payment gateways and encrypted data transmission.
                                                </li>
                                            </ul>
                                            <h5>4.2 Access Controls :</h5>
                                            <ul className="ifi-list">
                                                <li>Limited access to personal data to authorized personnel only.
                                                </li>
                                            </ul>
                                            <h5>4.3 Regular Audits :</h5>
                                            <ul className="ifi-list">
                                                <li>Routine security checks to detect and address vulnerabilities.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">5.</span>
                                            <span className="terms-text">
                                                  Your Rights
                                            </span>
                                        </h4>
                                        <p>You have the following rights regarding your personal data:</p>
                                        <p className='terms-condition-style'>
                                            <h5>5.1 Access and Correction:</h5>
                                            <ul className="ifi-list">
                                                <li>Request access to your personal data or corrections to inaccurate information.
                                                </li>
                                            </ul>
                                            <h5>5.2 Data Deletion :</h5>
                                            <ul className="ifi-list">
                                                <li>Request the deletion of your personal data, subject to legal obligations (e.g., under the Information Technology Act, 2000).
                                                </li>
                                            </ul>
                                            <h5>5.3 Opt-Out :</h5>
                                            <ul className="ifi-list">
                                                <li>Opt-out of marketing communications by following the unsubscribe link in emails or contacting us directly.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>


                                    <div className="single-terms">
                                        <h4><span className="number">6.</span>
                                            <span className="terms-text">
                                                    Third-Party Links
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Our website and app may contain links to third-party websites. We are not responsible for their privacy practices, and we encourage you to review their policies before engaging with them.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">7.</span>
                                            <span className="terms-text">
                                                    Children’s Privacy
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>Our website and app are not intended for children under the age of 13. We do not knowingly collect personal information from children. If we discover such data has been provided, we will delete it promptly.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">8.</span>
                                            <span className="terms-text">
                                                   Changes to the Privacy Policy
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>We reserve the right to update this Privacy Policy at any time. Changes will be effective upon posting to this page. Please review this page periodically to stay informed.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h3>Cookie Policy</h3>
                                    <p>
                                        At India's Future Investors, we use cookies and similar technologies to enhance
                                        your browsing experience and improve our website and app functionality. This
                                        Cookie Policy explains how we use cookies, what types of cookies we use, and how
                                        you can manage your cookie preferences.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="company-terms">
                                    <div className="single-terms">
                                        <h4><span className="number">1.</span>
                                            <span className="terms-text">
                                                   What Are Cookies?
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                            <li>Cookies are small text files that are stored on your device when you
                                                    visit our website or use our app. These files allow us to recognize
                                                    your device and store certain information about your preferences or
                                                    previous actions, which can help improve your user experience.
                                                </li>
                                            </ul>
                                        </p>


                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">2.</span>
                                            <span className="terms-text">
                                                  Types of Cookies We Use
                                            </span>
                                        </h4>
                                        <p>We use the following types of cookies on our website and app:</p>
                                        <p className='terms-condition-style'>
                                            <h5>2.1 Essential Cookies :</h5>
                                            <ul className="ifi-list">
                                                <li>These cookies are necessary for the basic functionality of our
                                                    website and app. Without these cookies, certain services may not be
                                                    available, such as logging in or making a purchase.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>2.2 Performance Cookies :</h5>
                                            <ul className="ifi-list">
                                                <li>These cookies collect information about how you use our website and
                                                    app, such as the pages you visit and how long you stay on them. This
                                                    data helps us improve website performance and user experience.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>2.3 Functional Cookies :</h5>
                                            <ul className="ifi-list">
                                                <li>These cookies allow us to remember your preferences (e.g., language
                                                    or region) and provide enhanced features, such as saving your login
                                                    credentials or personalized content.
                                                </li>
                                            </ul>
                                        </p>
                                        <p className='terms-condition-style'>
                                            <h5>2.4 Marketing Cookies :</h5>
                                            <ul className="ifi-list">
                                                <li>These cookies are used to track your browsing activity and show you
                                                    targeted advertisements based on your interests. We may use these
                                                    cookies to deliver personalized ads and measure the effectiveness of
                                                    our marketing campaigns.
                                                </li>

                                            </ul>
                                        </p>

                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">3.</span>
                                            <span className="terms-text">Managing Cookies</span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul>
                                                <li>You can manage or disable cookies through your browser settings.
                                                    Most web browsers allow you to block or delete cookies, and you can
                                                    set preferences for how cookies are handled. However, please note
                                                    that disabling cookies may impact the functionality of our website
                                                    and app, and some features may not work as intended.
                                                </li>
                                            </ul>
                                            <h5>To manage cookie settings in popular browsers:</h5>
                                            <ul className="ifi-list">
                                                <li>Google Chrome: Settings > Privacy and Security > Cookies and Other
                                                    Site Data
                                                </li>
                                                <li>Mozilla Firefox: Options > Privacy & Security > Cookies and Site
                                                    Data
                                                </li>
                                                <li>Safari: Preferences > Privacy > Cookies and Website Data</li>
                                                <li>Microsoft Edge: Settings > Privacy, Search, and Services > Cookies
                                                    and Site Data
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">4.</span>
                                            <span className="terms-text">
                                                    Third-Party Cookies
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>We may allow third-party services, such as Google Analytics and
                                                    social media platforms, to set cookies on our website. These third
                                                    parties may use the information collected from cookies to provide
                                                    services like analytics or personalized advertising. We are not
                                                    responsible for the privacy practices or content of these
                                                    third-party services.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">5.</span>
                                            <span className="terms-text">
                                                    Consent
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>By using our website and app, you consent to the use of cookies in
                                                    accordance with this Cookie Policy. If you do not agree to the use
                                                    of cookies, please disable them using your browser settings or
                                                    refrain from using our website.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4><span className="number">6.</span>
                                            <span className="terms-text">
                                                   Changes to the Cookie Policy
                                            </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <ul className="ifi-list">
                                                <li>We reserve the right to update or change this Cookie Policy at any
                                                    time. Any updates will be posted on this page, and the "Last
                                                    Updated" date will be revised accordingly. We encourage you to
                                                    review this policy periodically for any changes.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="single-terms">
                                        <h4>
                                            <span className="number">7.</span>
                                            <span className="terms-text">
                                               Contact Us
                                             </span>
                                        </h4>
                                        <p className='terms-condition-style'>
                                            <h5>If you have any questions or concerns about our use of cookies or this
                                                Cookie Policy, please contact us at:</h5>
                                            <ul>
                                                <li>
                                                    Email: <a
                                                    href="mailto:support@indiasfutureinvestors.com"
                                                    target="_blank"> support@indiasfutureinvestors.com</a>
                                                </li>
                                            </ul>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain/>
                <Footer/>

            </div>
        </>
    )
}
export default PrivacyPolicy