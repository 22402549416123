import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="footer-area-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="copyright">
                                {/*<p data-v-903b52d0="">*/}
                                {/*    Copyright © 2024{" "}*/}
                                {/*    <a href="#">*/}
                                {/*        India's Future Investors{" "}*/}
                                {/*    </a>{" "}*/}
                                {/*    All Rights Reserved*/}
                                {/*    */}
                                {/*</p>*/}
                                <h3 className='text-white'>Disclaimer</h3>
                                <p>India’s Future Investors (IFI) is an educational television show designed to promote financial literacy and investment education. The content presented on the show is for informational and educational purposes only and should not be construed as financial, investment, or trading advice. IFI does not offer or promote live trading, simulated trading, portfolio management, or investment advisory services.

Participants and viewers are advised not to make financial decisions based solely on the content of the show. The show complies with SEBI regulations and does not provide stock tips, investment recommendations, or guidance on real trading strategies.

IFI and its producers emphasize that investing involves risks, and individuals should consult a licensed financial advisor or conduct thorough due diligence before making any investment decisions. The views and opinions expressed by participants, experts, or judges on the show are their own and do not represent the official stance of IFI or its affiliates.

By engaging with the show, participants and viewers acknowledge that IFI is solely a learning platform and accept full responsibility for their financial decisions.

For further information on SEBI guidelines and regulations, please visit <a href="https://www.sebi.gov.in/" target="_blank"> www.sebi.gov.in.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer